/* eslint-disable */

import React, { useState, useEffect, Fragment } from "react";
import { IonPage, IonContent, IonHeader, IonFooter } from "@ionic/react";
import TitleBar from "../../../components/TitleBar";
import { BarcodeScanner } from "@ionic-native/barcode-scanner";
import ApiService from "../../../services/ApiService";
import { Camera, CameraOptions } from "@ionic-native/camera";
import {
  showNotification,
  showValidationErrors,
} from "../../../services/HelperMethod";
import moment from "moment";
import { storage } from "../../../components/Firebase";
import LoadingIndicator from "../../../components/LoadingIndicator";

const VerificationForm = (props: any) => {
  const apiService = ApiService();

  const [deviceData, setDeviceData] = useState(null);
  const [isBarCode, setIsBarCode] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [barCodeImage, setBarCodeImage] = useState(
    "/assets/icon/app/barcode.png"
  );
  const [isSetImageFront, setIsSetImageFront] = useState(false);
  const [isSetImageBack, setIsSetImageBack] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [imageFront, setImageFront] = useState(
    "/assets/icon/app/add-image.png"
  );
  const [imageBack, setImageBack] = useState("/assets/icon/app/add-image.png");
  const [imageTop, setImageTop] = useState("/assets/icon/app/add-image.png");
  const [imageBottom, setImageBottom] = useState(
    "/assets/icon/app/add-image.png"
  );
  const [imageLeft, setImageLeft] = useState("/assets/icon/app/add-image.png");
  const [imageRight, setImageRight] = useState(
    "/assets/icon/app/add-image.png"
  );
  const [checkSerialNumber, setCheckSerialNumber] = useState(null);
  const [checkModelNumber, setModelNumber] = useState(null);
  const [questionsState, setQuestionsState] = useState([]);
  const [isEnableTopImage, setIsEnableTopImage] = useState(false);
  const [isEnableBottomImage, setIsEnableBottomImage] = useState(false);
  const [isEnableLeftImage, setIsEnableLeftImage] = useState(false);
  const [isEnableRightImage, setIsEnableRightImage] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalBody, setModalBody] = useState("");
  const [firebaseImageArr, setFirebaseImageArr] = useState([] as any);
  const [firebaseImageURLArr, setFirebaseImageURLArr] = useState([] as any);

  const options: CameraOptions = {
    destinationType: Camera.DestinationType.DATA_URL,
    correctOrientation: true,
  };

  function handleSetQuestionsState(questionId, state, failedReason) {
    let isFound = false;
    var newQuestionState = [...questionsState];

    if (!newQuestionState) {
      newQuestionState = [];
    }

    newQuestionState.forEach((qs) => {
      if (qs.questionId == questionId) {
        qs.state = state;
        qs.reason = failedReason;
        isFound = true;
      }
    });

    if (!isFound) {
      newQuestionState.push({
        questionId: questionId,
        state: state,
        reason: failedReason,
      });
    }

    if (newQuestionState) {
      setQuestionsState(newQuestionState);
    }
  }

  useEffect(() => {
    setLoading(true);
	if (props?.match?.params?.quickScan){
		setIsBarCode(true);
        setBarCodeImage("/assets/icon/app/success.png");
	}
	

    apiService
      .getVerificationEquipment(props?.match?.params?.deviceId)
      .then((data: any) => {
        data = data.data;

        setLoading(false);
        setDeviceData(data);

        if (data.equipment?.topImageURL) {
          setIsEnableTopImage(true);
        }
        if (data.equipment?.leftImageURL) {
          setIsEnableLeftImage(true);
        }
        if (data.equipment?.rightImageURL) {
          setIsEnableRightImage(true);
        }
        if (data.equipment?.bottomImageURL) {
          setIsEnableBottomImage(true);
        }
      });
  }, []);

  useEffect(() => {
    if (
      (checkModelNumber != null &&
        checkSerialNumber != null &&
        isSetImageFront &&
        questionsState.length == deviceData?.questions?.length) ||
      isValid
    ) {
      setIsValid(true);
    } else {
      setIsValid(false);
    }
  });

  useEffect(() => {
    if (
      firebaseImageURLArr.length == firebaseImageArr.length &&
      firebaseImageURLArr.length > 0
    ) {
      saveData();
    }
  }, [firebaseImageURLArr]);

  const openScanner = async () => {
    try {
      const data = await BarcodeScanner.scan({ resultDisplayDuration: 0 });

      if (data.text == deviceData?.equipment?.assetTag) {
        setIsBarCode(true);
        setBarCodeImage("/assets/icon/app/success.png");
      } else {
        setIsBarCode(false);
        setBarCodeImage("/assets/icon/app/success.png");
      }
    } catch (err) {
      showNotification("Error reading barcode!");
      setIsBarCode(false);
      setIsValid(true);
    }
  };

  function uploadImageToFirebase() {
    setLoading(true);

    firebaseImageArr.forEach((element) => {
      const imageName =
        element.id + "_" + moment.now() + "_" + props?.match?.params?.deviceId;

      const upload = storage
        .ref(`verification/${imageName}`)
        .putString(element.image, "base64", { contentType: "image/png" });

      upload.on(
        "state_changed",
        (snapshot) => {},
        (error) => {
          console.log("upload image:", error);
          showNotification("Failed To Upload Image");
          setFirebaseImageURLArr([]);
          setLoading(false);
        },
        () => {
          storage
            .ref("verification")
            .child(imageName)
            .getDownloadURL()
            .then((url) => {
              var imageURLObj = { id: element.id, URL: url };

              setFirebaseImageURLArr((prevState) => [
                ...prevState,
                imageURLObj,
              ]);
            });
        }
      );
    });
  }

  function saveData() {
    var frontImage = firebaseImageURLArr.find(
      (element) => element.id == "front"
    );
    var backImage = firebaseImageURLArr.find((element) => element.id == "back");
    var leftImage = firebaseImageURLArr.find((element) => element.id == "left");
    var rightImage = firebaseImageURLArr.find(
      (element) => element.id == "right"
    );

    let data = {
      deviceId: props?.match?.params?.deviceId,
      verificationId: props?.match?.params?.laneId,
      questionAnswers: questionsState,
      FrontImage64: frontImage?.URL || "",
      BackImage64: backImage?.URL || "",
      LeftImage64: leftImage?.URL || "",
      RightImage64: rightImage?.URL || "",
      IsModelMatch: checkModelNumber,
      IsSerialMatch: checkSerialNumber,
      IsAssetMatch: isBarCode,
    };

    setLoading(true);

    apiService
      .verifyDevice(data)
      .then((data) => {
        data = data.data;

        setLoading(false);
        showNotification("Verification Completed");

        props.history.push("/home");
      })
      .catch((err) => {
        showValidationErrors(err);
        setLoading(false);
      });
  }

  function getPicture(section) {
    Camera.getPicture(options).then((data) => {
      if (section == "front") {
        setImageFront("data:image/png;base64," + data);
        setIsSetImageFront(true);

        var tempArr = [];
        var imageObj = { id: section, image: data };

        if (firebaseImageArr.length != 0) {
          tempArr = firebaseImageArr;

          var removeIndex = tempArr
            .map(function (item) {
              return item.id;
            })
            .indexOf(imageObj.id);

          if (removeIndex != -1) {
            tempArr.splice(removeIndex, 1);
          }

          tempArr.push(imageObj);
          setFirebaseImageArr(tempArr);
        } else {
          setFirebaseImageArr([imageObj]);
        }
      } else if (section == "back") {
        setImageBack("data:image/png;base64," + data);
        setIsSetImageBack(true);

        var tempArr = [];
        var imageObj = { id: section, image: data };

        if (firebaseImageArr.length != 0) {
          tempArr = firebaseImageArr;

          var removeIndex = tempArr
            .map(function (item) {
              return item.id;
            })
            .indexOf(imageObj.id);

          if (removeIndex != -1) {
            tempArr.splice(removeIndex, 1);
          }

          tempArr.push(imageObj);
          setFirebaseImageArr(tempArr);
        } else {
          setFirebaseImageArr([imageObj]);
        }
      } else if (section == "top") {
        setImageTop("data:image/png;base64," + data);
      } else if (section == "bottom") {
        setImageBottom("data:image/png;base64," + data);
      } else if (section == "left") {
        setImageLeft("data:image/png;base64," + data);

        var tempArr = [];
        var imageObj = { id: section, image: data };

        if (firebaseImageArr.length != 0) {
          tempArr = firebaseImageArr;

          var removeIndex = tempArr
            .map(function (item) {
              return item.id;
            })
            .indexOf(imageObj.id);

          if (removeIndex != -1) {
            tempArr.splice(removeIndex, 1);
          }

          tempArr.push(imageObj);
          setFirebaseImageArr(tempArr);
        } else {
          setFirebaseImageArr([imageObj]);
        }
      } else if (section == "right") {
        setImageRight("data:image/png;base64," + data);

        var tempArr = [];
        var imageObj = { id: section, image: data };

        if (firebaseImageArr.length != 0) {
          tempArr = firebaseImageArr;

          var removeIndex = tempArr
            .map(function (item) {
              return item.id;
            })
            .indexOf(imageObj.id);

          if (removeIndex != -1) {
            tempArr.splice(removeIndex, 1);
          }

          tempArr.push(imageObj);
          setFirebaseImageArr(tempArr);
        } else {
          setFirebaseImageArr([imageObj]);
        }
      }
    });
  }

  function verificationForm() {
    return (
      <div>
        <div className="top-stripe row align-items-center no-gutters">
          <div className="col">
            <p>Image Front *</p>
          </div>
          <div className="col-auto">
            <img
              style={{ width: "78px", height: "70px" }}
              onClick={() => {
                getPicture("front");
              }}
              src={imageFront}
            />
          </div>
        </div>

        <div className="mt-2 row align-items-center no-gutters">
          <div className="col">
            <p>Image Upper Left</p>
          </div>
          <div className="col-auto">
            <img
              style={{ width: "78px", height: "70px" }}
              onClick={() => {
                getPicture("left");
              }}
              src={imageLeft}
            />
          </div>
        </div>

        <div className="mt-2 row align-items-center no-gutters">
          <div className="col">
            <p>Image Lower Right</p>
          </div>
          <div className="col-auto">
            <img
              style={{ width: "78px", height: "70px" }}
              onClick={() => {
                getPicture("right");
              }}
              src={imageRight}
            />
          </div>
        </div>

        <div className="mt-2 row align-items-center no-gutters">
          <div className="col">
            <p>Image Back</p>
          </div>
          <div className="col-auto">
            <img
              style={{ width: "78px", height: "70px" }}
              onClick={() => {
                getPicture("back");
              }}
              src={imageBack}
            />
          </div>
        </div>

        <div className="row bottom-stripe  mt-3"></div>

        <div className="question-wrapper top-stripe">
          {deviceData?.questions.map((question, i) => {
            return <div key={i}>{Questions(question)}</div>;
          })}
        </div>
      </div>
    );
  }

  function Questions(question) {
    return (
      <div className="row bottom-stripe">
        <div className="col-12 mb-3 mt-2 question-text">
          {" "}
          {question?.question} *
        </div>
        <div className="col-12">
          <div
            className="col-sm-12"
            style={{ display: "flex", justifyContent: "space-around" }}
          >
            <div className="threeqtr">
              <p>Pass</p>
              <label className="q-radio-inline q-radio">
                <input
                  type="radio"
                  onChange={(e) => {
                    handleSetQuestionsState(question.id, 1, "");
                  }}
                  className="q-radio-custom green"
                  name={question?.id}
                />
                <span className="q-checkmark"></span>
              </label>
            </div>
            <div className="threeqtr">
              <p className="">Fail</p>
              <label className="q-radio-inline-red q-radio ">
                <input
                  type="radio"
                  onChange={(e) => {
                    handleSetQuestionsState(question.id, 2, "");
                  }}
                  className="q-radio-custom"
                  name={question?.id}
                />
                <span className="q-checkmark"></span>
              </label>
            </div>
            <div className="threeqtr">
              <p className="">N/A</p>
              <label className="q-radio-inline q-radio">
                <input
                  type="radio"
                  onChange={(e) => {
                    handleSetQuestionsState(question.id, 3, "");
                  }}
                  className="q-radio-custom"
                  name={question?.id}
                />
                <span className="q-checkmark"></span>
              </label>
            </div>
          </div>
        </div>

        {getReason(question)}
      </div>
    );
  }

  function getQuestionState(questionId) {
    let state = 0;

    questionsState.forEach((qs) => {
      if (qs.questionId == questionId) {
        state = qs.state;
      }
    });

    return state;
  }

  function getReason(question) {
    var state = getQuestionState(question.id);

    if (state == 2) {
      return (
        <div className="col-12 mb-3">
          <textarea
            name="reason"
            onChange={(e) => {
              handleSetQuestionsState(question.id, 2, e.target.value);
            }}
            className="fail-reason form-control"
          ></textarea>
        </div>
      );
    } else {
      return <></>;
    }
  }

  return (
    <IonPage className="safe-view">
      <IonHeader>
        <TitleBar title={deviceData?.equipment?.lane?.name} />
      </IonHeader>

      <IonContent>
        <div className="due-wrapper">
          <LoadingIndicator isLoading={isLoading} />
          <div className="row mt-4 ion-align-items-end">
            <div className="col">
              <div className="label">Model Number *</div>
              <div className="store-dist-text">
                <p>{deviceData?.equipment?.model}</p>
              </div>
            </div>

            <div className="auto">
              <label className="radio-inline">
                <p>No</p>
                <input
                  type="radio"
                  className="radio-custom"
                  onChange={() => {
                    setModelNumber(false);
                    showAppModal(
                      "Model Number Mistmatch?",
                      `You've said that the model number on the device
                                        does not match '${deviceData?.equipment?.model}' do you want to continue?`
                    );
                  }}
                  name="model-match"
                />
                <span className="checkmark"></span>
              </label>

              <label className="radio-inline">
                <p>Yes</p>
                <input
                  type="radio"
                  className="radio-custom green"
                  onChange={() => {
                    setModelNumber(true);
                  }}
                  name="model-match"
                />
                <span className="checkmark"></span>
              </label>
            </div>
          </div>

          <div className="row ion-align-items-end">
            <div className="col">
              <div className="label">Serial Number *</div>
              <div className="store-dist-text">
                <p>{deviceData?.equipment?.serial}</p>
              </div>
            </div>

            <div className="auto">
              <label className="radio-inline">
                <p>No</p>
                <input
                  type="radio"
                  className="radio-custom"
                  onChange={() => {
                    setCheckSerialNumber(false);
                    showAppModal(
                      "Serial Number Mistmatch?",
                      `You've said that the Serial number on the device
                                        does not match '${deviceData?.equipment?.serial}' do you want to continue?`
                    );
                  }}
                  name="serial-match"
                />
                <span className="checkmark"></span>
              </label>

              <label className="radio-inline">
                <p>Yes</p>
                <input
                  type="radio"
                  className="radio-custom green"
                  onChange={() => {
                    setCheckSerialNumber(true);
                  }}
                  name="serial-match"
                />
                <span className="checkmark"></span>
              </label>
            </div>
          </div>

          <div className="top-stripe bottom-stripe row mt-3 pb-3 align-items-center">
            <div className="col">
              <p className="font-weight-bold">PCeyeD Barcode *</p>
            </div>
            <div className="col-auto">
              <img
                style={{ width: "78px", height: "70px" }}
                onClick={() => {
                  openScanner();
                }}
                src={barCodeImage}
              />
            </div>
          </div>

          <div className="col-12 mt-3"></div>
          {verificationForm()}
        </div>

        {showModal ? (
          <div className="app-modal">
            <div className="modal-body">
              <div className="title">{modalTitle}</div>
              <div className="content">{modalBody}</div>
              <div className="modal-footer">
                <button
                  className="cancel btn"
                  onClick={() => {
                    props.history.goBack("/home");
                  }}
                >
                  Cancel
                </button>
                <button
                  className="primary btn"
                  onClick={() => {
                    setShowModal(false);
                  }}
                >
                  Continue
                </button>
              </div>
            </div>
          </div>
        ) : (
          <Fragment />
        )}
      </IonContent>

      <IonFooter>
        <div className="footer">
          <div className="row">
            <div className="col-12 footer-col-center">
              <span>
                {isValid ? (
                  <button
                    onClick={() => {
                      uploadImageToFirebase();
                    }}
                    className="center-x success"
                  >
                    <i className="fas fa-check"></i>
                  </button>
                ) : (
                  <p className="center-x">
                    <i className="fas fa-times"></i>
                  </p>
                )}
              </span>
            </div>
          </div>
        </div>
      </IonFooter>
    </IonPage>
  );

  function showAppModal(title, body) {
    setModalTitle(title);
    setModalBody(body);
    setShowModal(true);
  }
};

export default VerificationForm;
