/* eslint-disable */

import React, { Fragment, useContext, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { IonFooter } from "@ionic/react";
import ApiService from "../services/ApiService";
import packageJson from "../../package.json";
import { MessageCountContext } from "../store/Store";
import { checkClaim } from "../services/HelperMethod";
// const { PushNotifications } = Plugins;

const Footer = () => {
  const apiService = ApiService();

  const [messageCount, setMessageCount] = useContext(MessageCountContext);

  let isUnmounted = false;

  useEffect(() => {
    // PushNotifications.addListener(
    //   "pushNotificationReceived",
    //   (notification: PushNotification) => {
    //       if(!isUnmounted){
    //           checkMessage();
    //       }
    //   }
    // );
    checkMessage();

    return () => {
      isUnmounted = true;
    };
  }, []);

  async function checkMessage() {
    apiService.checkNewMessages().then((data: any) => {
      data = data.data;
      setMessageCount(data.length);
    });

    setTimeout(() => {
      if (!isUnmounted) {
        checkMessage();
      }
    }, 5000);
  }

  function getMessageCountUI() {
    if (messageCount == 0) {
      return <></>;
    }

    return (
      <div className="message-count animate__animated animate__bounce">
        {messageCount}
      </div>
    );
  }

  function navAssetUI() {
    let isManager = checkClaim("Manager");

    if (!isManager) {
      return <></>;
    }

    return (
      <Fragment>
        <NavLink to="/assetStore" className="footer-col col">
        <img style={{ width: "20px", height: "20px" }} src={'/assets/icon/app/assets.svg'} />
          <br />
          <p className="footer-text">ASSETS</p>
        </NavLink>

        <NavLink to="/verificationStore" className="footer-col col">
		<img style={{ width: "25px", height: "25px" }} src={'/assets/icon/app/pci.svg'} />
          <br />
          <p className="footer-text">TERMINALS</p>
        </NavLink>
      </Fragment>
    );
  }

  return (
    <IonFooter>
      <div className="footer">
        <div className="container">
          <div className="row">
            <NavLink to="/home" className="footer-col col">
              <i className="fas fa-home footer-icon mt-1"></i>
              <br />
              <p className="footer-text">HOME</p>
            </NavLink>
            {navAssetUI()}
            <NavLink to="/message" className="footer-col col">
              <i className="fas fa-envelope footer-icon mt-1"></i>
              <br />
              {getMessageCountUI()}
              <p className="footer-text">MESSAGE</p>
            </NavLink>
            <NavLink to="/profile" className="footer-col col">
              <i className="far fa-user-circle footer-icon mt-1"></i>
              <br />
              <p className="footer-text">PROFILE</p>
            </NavLink>
          </div>
        </div>
        <div className="top-version-number">V{packageJson.version}</div>
      </div>
    </IonFooter>
  );
};

export default Footer;
