/* eslint-disable */

import React, { useState, useEffect } from "react";
import { IonPage, IonContent, IonHeader } from "@ionic/react";
import Footer from "../components/Footer";
import Logout from "../components/Logout";
import ApiService from "../services/ApiService";
import { showNotification, getImage } from "../services/HelperMethod";
import LoadingIndicator from "../components/LoadingIndicator";
import { Camera, CameraOptions } from "@ionic-native/camera";

const Profile = (props: any) => {
  const apiService = ApiService();

  const [isValid, setIsValid] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [profileImage, setProfileImage] = useState("");
  const [selectedRole, setSelectedRole] = useState("");

  const options: CameraOptions = {
    destinationType: Camera.DestinationType.DATA_URL,
  };

  useEffect(() => {
    validate();
  });

  useEffect(() => {
    setIsLoading(true);

    apiService.getUser().then(({ data }) => {
      setIsLoading(false);
      setProfile(data);
    }).catch(() => {
      showNotification("Error try again!");
      setIsLoading(true);
    });
  }, []);

  const onSubmit = () => {
    setIsLoading(true);

    apiService.updateUser({ firstName, lastName, email, username, password, profileImage, }).then(({ data }) => {
      setIsLoading(false);
      showNotification("Profile saved!");
    }).catch(() => {
      setIsLoading(false);
      showNotification("Error try again!");
    });
  };


  return (
    <IonPage className="safe-view">
      <IonHeader>
        <nav className="row align-items-center pt-3 pb-3 bg-light">
          <div className="col offset-3 text-center">
            <img className="pci-verification-pcid-logo" src="./assets/images/pcid_logo.png" alt="pcid" width="120" />
          </div>
          <Logout />
        </nav>
      </IonHeader>

      <IonContent>
        <div>
          <LoadingIndicator isLoading={isLoading} />
          <div>
            <h5 className="text-center mt-3 mb-3 font-weight-bold">Profile</h5>
            <div className="text-center">
              <div className="profile-img" onClick={() => {
                Camera.getPicture(options).then((data) => { setProfileImage("data:image/png;base64," + data); });
              }}>
                {getImage(profileImage, "./assets/icon/app/profile.png")}
              </div>
            </div>
            <div className="form-group form-group-padded">
              <label htmlFor="fname" className="label">
                First Name *
              </label>
              <input type="text" id="fname" className="form-control" value={firstName}
                onChange={(e) => { setFirstName(e.target.value); }} />
            </div>
            <div className="form-group form-group-padded">
              <label htmlFor="lname" className="label">
                Last Name *
              </label>
              <input type="text" id="lname" className="form-control" value={lastName} onChange={(e) => { setLastName(e.target.value); }} />
            </div>
            <div className="form-group form-group-padded">
              <label htmlFor="email" className="label">
                Email *
              </label>
              <input type="text" id="email" className="form-control" value={email} onChange={(e) => { setEmail(e.target.value); }} />
            </div>
            <div className="form-group form-group-padded">
              <label htmlFor="username" className="label">
                Username *
              </label>
              <input type="text" id="username" className="form-control" value={username}
                onChange={(e) => { setUsername(e.target.value); }} />
            </div>
            <div className="form-group form-group-padded">
              <label htmlFor="password" className="label">
                Password *
              </label>
              <input placeholder="●●●●●●" type="password" id="password" className="form-control" autoComplete="new-password"
                value={password} onChange={(e) => { setPassword(e.target.value); }} />
            </div>
            <div className="form-group form-group-padded">
              <label htmlFor="security" className="label">
                Security
              </label>
              <input placeholder="" type="input" id="secuirty" className="form-control" disabled value={selectedRole} />
            </div>

            <div className="plus-button row justify-content-center">
              {submitButton()}
            </div>
          </div>
        </div>
      </IonContent>

      <Footer />
    </IonPage>
  );

  function setProfile(data: any) {
    setFirstName(data.firstName);
    setLastName(data.lastName);
    setEmail(data.email);
    setUsername(data.username);
    setPassword(data.password);
    setProfileImage(data.imageURL);
    setSelectedRole(data.selectedRole)
  }

  function validate() {
    setIsValid(false);
    if (firstName && lastName && email && username) {
      setIsValid(true);
    }
  }

  function submitButton() {
    if (isValid) {
      return (
        <p onClick={onSubmit} className="center-x success">
          <i className="fas fa-check"></i>
        </p>
      );
    } else {
      return (
        <p onClick={() => { }} className="center-x">
          <i className="fas fa-times"></i>
        </p>
      );
    }
  }
};

export default Profile;
