/* eslint-disable */

import React, { useState, useEffect } from "react";
import { IonPage, IonContent, IonHeader, IonFooter } from "@ionic/react";
import TitleBar from "../components/TitleBar";
import ApiService from "../services/ApiService";
import moment from "moment";
import { Camera, CameraOptions } from "@ionic-native/camera";
import { showNotification, getImageURL } from "../services/HelperMethod";
import { toast } from "react-toastify";
import LoadingIndicator from "../components/LoadingIndicator";
import { Chooser } from "@ionic-native/chooser";
import { BarcodeScanner } from "@ionic-native/barcode-scanner";

const CreateAsset = (props: any) => {

  const apiService = ApiService();

  const [isValid, setIsValid] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [departmentId, setDepartmentId] = useState(0);
  const [departments, setDepartments] = useState([]);
  const [barcode, setBarcode] = useState("");
  const [serialNumber, setSerialNumber] = useState("");
  const [modelNumber, setModelNumber] = useState("");
  const [date, setDate] = useState(moment());
  const [initialCost, setInitCost] = useState(0);
  const [replacementCost, setReplacementCost] = useState(0);
  const [description, setDescription] = useState("");
  const [note, setNote] = useState("");
  const [assetType, setAssetType] = useState(0);
  const [imageMain, setImageMain] = useState("");
  const [isSetMainImage, setIsSetMainImage] = useState(false);
  const [additionalImages, setAdditionalImages] = useState([]);
  const [additionalAttachments, setAdditionalAttachments] = useState([]);

  const options: CameraOptions = {
    destinationType: Camera.DestinationType.DATA_URL,
  };

  const openScanner = async () => {
	try {
		const data = await BarcodeScanner.scan({ resultDisplayDuration: 0 });
		return data.text;
	} catch (err) {
		showNotification("Invalid Barcode Please Try Again");
	}
};

  useEffect(() => { checkValidations(); });

  useEffect(() => {
    setIsLoading(true);

    apiService.getDepartments(props?.match?.params?.storeId).then(({ data }) => {
      setDepartments(data);

      if (data.length <= 0) {
        showNotification("No departments setup!. Please go to admin panel and create");
      }

      handleEdit();
    }).catch((err) => {
      setIsLoading(false);
      handleFormErrors(err?.response?.data?.errors);
    });
  }, []);

  function handleEdit() {
    if (props?.match?.params?.assetId) {
      apiService.getAssetsById(props?.match?.params?.assetId).then((data: any) => {

        data = data.data;

		
        setAssetType(data.assetType);
        setDepartmentId(data.departmentId);
        setSerialNumber(data.serialNumber);
        setModelNumber(data.modelNumber);
        setDate(moment(data.date).local());
        setInitCost(data.initialCost);
        setReplacementCost(data.replacementCost);
        setNote(data.note);
        setImageMain(data.imageURL);
        setBarcode(data.barcode)
        setDescription(data.description);

        var arrAdditionalImages = data?.additionalImages?.split(",");

        arrAdditionalImages = arrAdditionalImages?.filter((item) => {
          return item;
        });

        var arrAdditionalAttachments = data?.additionalAttachments?.split(",");

        arrAdditionalAttachments = arrAdditionalAttachments?.filter((item) => {
          return item;
        });

        arrAdditionalImages = arrAdditionalImages?.map((item) => {
          return item;
        });

        arrAdditionalAttachments = arrAdditionalAttachments?.map((item) => {
          return item;
        });

        setAdditionalAttachments(arrAdditionalAttachments);
        setAdditionalImages(arrAdditionalImages);

        setIsLoading(false);
        setIsSetMainImage(true);
      }).catch((err) => {
        showNotification("Error while getting asset data! go back and try again");
      });
    } else {
      setIsLoading(false);
    }
  }

  function handleFormErrors(err) {
    if (err) {
      showNotification("Please fill all the required fields");
    } else {
      showNotification("Error! try again");
    }
  }

  function checkValidations() {
    if (departmentId && serialNumber && modelNumber && initialCost && isSetMainImage && assetType && departmentId && barcode) {
      setIsValid(true);
    } else {
      setIsValid(false);
    }
  }

  function createAsset() {
    setIsLoading(true);

    apiService.createAsset({
      id: props?.match?.params?.assetId,
      name,
      departmentId,
      serialNumber,
      modelNumber,
      initialCost,
      date,
      replacementCost,
      note: note,
      assetType,
      description,
      mainImage: imageMain,
      additionalImages,
      additionalAttachments,
      barcode
    }).then((data: any) => {
      if (data.errorCode) {
        toast.error(data.errorMessage);
      } else {
        if (props?.match?.params?.assetId) {
          showNotification("Asset Updated!");
        } else {
          showNotification("Asset created!");
        }

        props.history.push("/assets/" + props?.match?.params?.storeId);
      }
    }).catch((err) => {
      setIsLoading(false);
      handleFormErrors(err?.response?.data?.errors);
    });
  }

  function submitButton() {
    if (isValid) {
      return (
        <p onClick={() => { createAsset(); }} className="center-x success">
          <i className="fas fa-check"></i>
        </p>
      );
    } else {
      return (
        <p onClick={() => { }} className="center-x">
          <i className="fas fa-times"></i>
        </p>
      );
    }
  }

  function getPicture(section, arrItem = 0) {
    Camera.getPicture(options).then((data) => {
      if (section == "main") {
        setImageMain("data:image/png;base64," + data);
        setIsSetMainImage(true);
      } else if (section == "additionalImage") {
        var additionalImageRef = [...additionalImages];
        additionalImageRef[arrItem] = "data:image/png;base64," + data;
        setAdditionalImages(additionalImageRef);
      }
    });
  }

  useEffect(() => {
    console.log(additionalAttachments)
  }, [additionalAttachments])

  function getFile(arrItem = 0) {
    Chooser.getFile("application/pdf").then((file) => {
      var additionalImageRef = [...additionalAttachments];
      additionalImageRef[arrItem] = file.dataURI;
      setAdditionalAttachments(additionalImageRef);
    }).catch((err) => {
      console.log(err, "err")
    })
  }

  function getTitle() {
    if (props?.match?.params?.assetId) {
      return "Edit Asset";
    } else {
      return "Create Asset";
    }
  }

  return (
    <IonPage className="safe-view">
      <IonHeader>
        <TitleBar title={getTitle()} />
      </IonHeader>

      <IonContent>
        <div className="row mb-5">

          <LoadingIndicator isLoading={isLoading} />

          <div className="col-sm-12 bottom-stripe" />
          <div className="col-sm-12 bottom-stripe">
            <div className="row">
              <div className="col-8 d-flex align-items-center">
                <p className="pl-4 m-0 font-weight-bold">Main Image *</p>
              </div>
              <div className="col-4 text-right">
                <img style={{ width: "80px" }} onClick={() => { getPicture("main"); }} src={getImageURL(imageMain)} alt="" />
              </div>
            </div>
          </div>

          <div className="col-sm-12 col-white">

            <div className="form-group mt-4 pl-3 pr-3 w-100">
              <label className="pl-2 font-weight-bold">Type *</label>
              <select value={assetType} className="form-control" onChange={(e) => { setAssetType(+e.target.value); }}>
                <option value="0">Choose type</option>
                <option value="1">Moving equipment</option>
                <option value="2">Janitorial</option>
                <option value="3">Electronics</option>
                <option value="4">Major appliances</option>
                <option value="5">Vehicles</option>
              </select>
            </div>

            <div className="form-group mt-4 pl-3 pr-3 w-100">
              <label className="pl-2 font-weight-bold">Department *</label>
              <select className="form-control" onChange={(e) => { setDepartmentId(+e.target.value); }} value={departmentId}>
                {departments.map((department) => {
                  return (
                    <React.Fragment key={department.id}>
                      <option value={0}>Choose department</option>
                      <option value={department.id}>{department.name}</option>
                    </React.Fragment>
                  );
                })}
              </select>
            </div>

            <div className="mt-4 pl-3 pr-3 w-100">
              <label className="pl-2 font-weight-bold">PCeyeD Barcode *</label>
              <div style={{display: "flex"}}>
				<input
					className="form-control"
					value={barcode}
					onChange={(e) => {
					setBarcode(e.target.value);
					}}
					type="text"
					placeholder=""
				/>
				<button onClick={() => { openScanner().then(res => {
					setBarcode(res)
				}); }}>	
					<i className="fas fa-solid fa-barcode footer-icon mt-1"></i>
                </button>
			  </div>
			</div>

            <div className="form-group mt-4 pl-3 pr-3 w-100">
              <label className="pl-2 font-weight-bold">Serial Number *</label>
			                <div style={{display: "flex"}}>
							<input
                className="form-control"
                value={serialNumber}
                onChange={(e) => {
                  setSerialNumber(e.target.value);
                }}
                type="text"
                placeholder=""
              />
				<button onClick={() => { openScanner().then(res => {
					setSerialNumber(res)
				}); }}>	
					<i className="fas fa-solid fa-barcode footer-icon mt-1"></i>
                </button>
			  </div>
            </div>

            <div className="form-group mt-4 pl-3 pr-3 w-100">
              <label className="pl-2 font-weight-bold">Model Number *</label>
			                <div style={{display: "flex"}}>
							<input
                className="form-control"
                value={modelNumber}
                onChange={(e) => {
                  setSerialNumber(e.target.value);
                }}
                type="text"
                placeholder=""
              />
				<button onClick={() => { openScanner().then(res => {
					setModelNumber(res)
				}); }}>	
					<i className="fas fa-solid fa-barcode footer-icon mt-1"></i>
                </button>
			  </div>
            </div>

            <div className="form-group mt-4 pl-3 pr-3 w-100">
              <label className="pl-2 font-weight-bold">Date *</label>
              <input type="date" className="form-control" value={date.format("YYYY-MM-DD")}
                onChange={(e) => { setDate(moment(e.target.value)); }} />
            </div>

            <div className="form-group mt-4 pl-3 pr-3 w-100">
              <label className="pl-2 font-weight-bold">Initial Cost *</label>
              <input className="form-control" onClick={(e: any) => { e.target.select(); }} value={initialCost}
                onChange={(e) => { setInitCost(parseFloat(e.target.value)); }} type="number" placeholder="" />
            </div>

            <div className="form-group mt-4 pl-3 pr-3 w-100">
              <label className="pl-2 font-weight-bold">Replacement Cost *</label>
              <input className="form-control" onClick={(e: any) => { e.target.select(); }} value={replacementCost}
                onChange={(e) => { setReplacementCost(parseFloat(e.target.value)); }} type="number" placeholder="" />
            </div>

            <div className="form-group mt-4 pl-3 pr-3 w-100">
              <label className="pl-2 font-weight-bold">Description </label>
              <input className="form-control" value={description} onChange={(e) => { setDescription(e.target.value); }}
                type="text" placeholder="" />
            </div>

            <div className="form-group mt-4 pl-3 pr-3 w-100">
              <label className="pl-2 font-weight-bold">Notes</label>
              <textarea value={note} onChange={(e) => { setNote(e.target.value); }} cols={10} rows={5}
                className=" form-control form-textarea">
              </textarea>
            </div>

            <div className="col-sm-12 bottom-stripe"></div>

            <div className="form-group mt-4 mb-1 pl-3 pr-3 w-100">
              <label className="pl-2 font-weight-bold">Additional Images</label>
            </div>

            <div className="row">
              <div className="col-sm-12 mb-3 mt-3 pl-2">
                <div onClick={() => { setAdditionalImages([...additionalImages, ""]); }} className="add-link ml-5">
                  <i className="fas fa-plus-circle mr-4 icon-green font-weight-bold"></i>
                  Additional Images
                </div>
              </div>
            </div>

            {additionalImages.slice(1).map((i) => {
              return (
                <div key={i} className="top-stripe row align-items-center no-gutters">
                  <div className="col">
                    <p className="pl-3 m-0">Attach Image (optional)</p>
                  </div>
                  <div className="col-auto">
                    <img style={{ width: "78px", height: "70px", }} onClick={() => { getPicture("additionalImage", i + 1); }}
                      src={getImageURL(additionalImages[i + 1])} />
                  </div>
                </div>
              );
            })}

            <div className="form-group mt-5 mb-1 pl-3 pr-3 w-100">
              <label className="pl-2 font-weight-bold">
                Attachments{" "}
                <span className="smaller-texts" style={{ float: "none" }}></span>
                <span className="ion-float-left smaller-texts mt-3">(Manuals, Warranties, etc..)</span>
              </label>
            </div>

            <div className="row">
              <div className="col-sm-12 mb-3 mt-3 pl-2">
                <div onClick={() => { setAdditionalAttachments([...additionalAttachments, ""]); }} className="add-link ml-5">
                  <i className="fas fa-plus-circle mr-4 icon-green font-weight-bold"></i>
                  Attachments
                </div>
              </div>
            </div>

            {additionalAttachments.slice(1).map((v, i) => {
              return (
                <div key={i} className="top-stripe row align-items-center no-gutters">
                  {console.log(i,"i")}
                  <div className="col"><p className="pl-3 m-0">Attach Documents (optional)</p></div>
                  {i.toString() != ""
                    ? (<div className="col-auto">
                      <img style={{ width: "78px", height: "70px" }} onClick={() => { getFile(i + 1); }}
                        src={'/assets/icon/app/success.png'} />
                    </div>)
                    : (<div className="col-auto">
                      <img style={{ width: "78px", height: "70px" }} onClick={() => { getFile(i + 1); }}
                        src={"/assets/icon/app/add-image.png"} />
                    </div>)}
                </div>
              );
            })}
          </div>
        </div>
      </IonContent>

      <IonFooter>
        <div className="footer">
          <div className="container">
            <div className="row">
              <div className="col-xs-3 footer-col"></div>
              <div className="col-xs-3 footer-col-center">
                <span className="center-x-link">{submitButton()}</span>
              </div>
              <div className="col-xs-3 footer-col"></div>
            </div>
          </div>
        </div>
      </IonFooter>
    </IonPage>
  );
};

export default CreateAsset;
