/* eslint-disable */

import React, { useState } from "react";
import { IonPage, IonContent } from "@ionic/react";

const Validate2FAMethods = (props: any) => {

    function forEmail() {
        props.history.push("validate2FA/email");
    }

    function forSMS() {
        props.history.push("validate2FA/sms");
    }

    return (
        <IonPage className="safe-view">

            <IonContent>
                <div className="login-wrap">
                    <div className="mt-4 mb-4 text-center">
                        <img src="./assets/images/pcid_logo.png" alt="pcid" className="login-logo" />
                    </div>

                    <div className="p-2 text-center text-uppercase font-weight-bold small text-secondary">
                        <p>New Device Confirmation Required!</p>
                    </div>

                    <div>
                        <button className="btn btn-pcid" onClick={() => { forEmail() }}>Email</button>
                        <button className="btn mt-2 btn-pcid" onClick={() => { forSMS() }}>SMS</button>
                    </div>
                </div>
            </IonContent>

        </IonPage>
    );
};

export default Validate2FAMethods;
