/* eslint-disable */

import React, { useState, useEffect } from "react";
import { IonPage, IonContent, IonHeader, IonFooter } from "@ionic/react";
import TitleBar from "../../../components/TitleBar";
import moment from "moment";
import ApiService from "../../../services/ApiService";
import { Camera, CameraOptions } from "@ionic-native/camera";
import { getBaseURL, showNotification } from "../../../services/HelperMethod";
import LoadingIndicator from "../../../components/LoadingIndicator";
import { BarcodeScanner } from "@ionic-native/barcode-scanner";
import { storage } from "../../../components/Firebase";

const AssetMaintenance = (props: any) => {
    const apiService = ApiService();

    const [isFinish, setIsFinish] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isComplete, setIsComplete] = useState(null);
    const [isSerialComplete, setIsSerialComplete] = useState(null);
    const [additionalInfo, setAdditionalInfo] = useState("");
    const [nextDate, setNextDate] = useState(moment().add(1, "day"));
    const [imageFront, setImageFront] = useState("/assets/icon/app/add-image.png");
    const [isSetImageFront, setIsSetImageFront] = useState(false);
    const [additionalImage, setAdditionalImage] = useState([]);
    const [maintenance, setMaintenance] = useState([] as any);
    const [isBarCode, setIsBarCode] = useState(false);
    const [barCodeImage, setBarCodeImage] = useState("/assets/icon/app/barcode.png");
    const [isValid, setIsValid] = useState(false);

    const [decodedMainFirbaseImage, setDecodedMainFirebaseImage] = useState(null);

    const options: CameraOptions = {
        destinationType: Camera.DestinationType.DATA_URL,
        correctOrientation: true,
    };

    useEffect(() => {
        setIsLoading(true);

        apiService.getMaintenanceByAssetId(props?.match?.params?.assetId).then((data: any) => {
            data = data.data;

            setMaintenance(data);
            setIsLoading(false);

            const fq = data?.frequency;

            let days = 0;

            if (fq == 1) {
                days = 7;
            }
            if (fq == 2) {
                days = 14;
            }
            if (fq == 3) {
                days = 30;
            }
            if (fq == 4) {
                days = 91;
            }
            if (fq == 5) {
                days = 365;
            }

            setNextDate(moment(data?.dueDate).local().add(days, "d"));
        });
    }, []);

    useEffect(() => {
        if (nextDate && isSetImageFront && isComplete != null && isSerialComplete != null && decodedMainFirbaseImage != null &&
            barCodeImage != "/assets/icon/app/barcode.png") {
            setIsFinish(true);
        } else {
            setIsFinish(false);
        }
    });

    function getPicture(section) {
        Camera.getPicture(options).then((data) => {
            if (section == "front") {
                setDecodedMainFirebaseImage(data);
                setImageFront("data:image/png;base64," + data);
                setIsSetImageFront(true);
            } else {
                let additionalImageRef = [...additionalImage];
                additionalImageRef[section] = "data:image/png;base64," + data;
                setAdditionalImage(additionalImageRef);
            }
        });
    }

    function uploadImageToFirebase(type, decodedImage) {
        setIsLoading(true);

        const imageName = type + "_" + moment.now() + "_" + props?.match?.params?.assetId;

        const upload = storage.ref(`maintenance/${imageName}`).putString(decodedImage, 'base64',
            { contentType: 'image/png' });

        upload.on("state_changed",
            snapshot => { },
            error => {
                showNotification("Error Saving Image. Please Try Again.");
                setIsLoading(false);
            },
            () => {
                storage.ref("maintenance").child(imageName)
                    .getDownloadURL().then(url => {
                        setNextMaintenanceDate(url)
                        setIsLoading(false);
                    })
            })
    }

    function setNextMaintenanceDate(FirebaseURL) {
        setIsLoading(true);

        let request = {
            IsCompete: isComplete,
            additionalInfo,
            assetId: parseInt(props?.match?.params?.assetId),
            image: FirebaseURL,
            nextDate,
            isBarCodeMatch: isBarCode,
            isSerialCodeMatch: isSerialComplete
        };

        apiService.createMaintenanceLog(request).then(() => {

            setIsLoading(false);
            showNotification("Maintenance Completed");

            props.history.push("/home");
        }).catch(() => {
            setIsLoading(false);
            showNotification("Error. Please Try Again.");
        });
    }

    const openScanner = async () => {
        try {
            const data = await BarcodeScanner.scan({
                resultDisplayDuration: 0,
            });

            if (data.text == maintenance?.asset?.barcode) {
                setIsBarCode(true);
                setBarCodeImage("/assets/icon/app/success.png");
            } else {
                setIsBarCode(false);
                setBarCodeImage("/assets/icon/app/success.png");
            }
        } catch (err) {
            showNotification("Error reading barcode!");
            setIsBarCode(false);
            setIsValid(true);
        }
    };

    function getDocs() {
        let assetDocuments = [];
        assetDocuments = maintenance?.asset?.additionalAttachments.split(",")

        let documentName
        let document

        if (assetDocuments) {
            assetDocuments.forEach(element => {
                if (element.trim() != "") {
                    document = element
                    let getDocumentName = element.split("/")

                    documentName = getDocumentName[2];
                }
            });
        }

        return <a href={getBaseURL() + "images/" + document} download>{documentName}</a>
    }

    return (
        <IonPage className="safe-view">
            <IonHeader>
                <TitleBar title={"Maintenance"} />
            </IonHeader>

            <IonContent>
                <div>
                    <LoadingIndicator isLoading={isLoading} />
                </div>

                <div className="maintenance-wrapper">
                    <p className="label pl-3 mt-3">
                        Description:Department
                    </p>

                    <div className="col-auto text-center" style={{ display: "flex", justifyContent: "flex-start" }}>
                        <div>{maintenance.asset?.description}:{maintenance.asset?.department?.name}</div>
                    </div>

                    <div className="col-sm-12 mt-4 bottom-stripe bottom-stripe-mt" />

                    <div style={{ display: "flex", flexDirection: "row" }}>
                        <div>
                            <p className="label pl-3 mt-3">
                                Serial Number *
                            </p>

                            <div className="col-auto text-center" style={{ display: "flex", justifyContent: "flex-start" }}>
                                <div>{maintenance.asset?.serialNumber}</div>
                            </div>
                        </div>
                        <div style={{ flex: "1", marginTop: "1.5rem" }}>
                            <div className="row justify-content-around">
                                <div className="col-auto text-center">
                                    <div>Yes</div>
                                    <label className="q-radio-inline q-radio">
                                        <input type="radio" onChange={() => { setIsSerialComplete(true); }} name="completed1" />
                                        <span className="q-checkmark" />
                                    </label>
                                </div>
                                <div className="col-auto text-center">
                                    <div>No</div>
                                    <label className="q-radio-inline q-radio">
                                        <input type="radio" onChange={() => { setIsSerialComplete(false); }} name="completed1" />
                                        <span className="q-checkmark" />
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-sm-12 mt-4 bottom-stripe bottom-stripe-mt" />

                    <div className="top-stripe row align-items-center no-gutters">
                        <div className="col">
                            <p className="pl-3 m-0 font-weight-bold">
                                PCeyeD Barcode *
                            </p>
                        </div>
                        <div className="col-auto">
                            <img style={{ width: "78px", height: "70px" }} onClick={() => { openScanner(); }}
                                src={barCodeImage} />
                        </div>
                    </div>

                    <div className="col-sm-12 mt-4 bottom-stripe bottom-stripe-mt" />

                    <div className="top-stripe row align-items-center no-gutters">
                        <div className="col">
                            <p className="pl-3 m-0 font-weight-bold">
                                Attach Image *
                            </p>
                        </div>
                        <div className="col-auto">
                            <img style={{ width: "78px", height: "70px" }}
                                onClick={() => { getPicture("front"); }} src={imageFront} />
                        </div>
                    </div>

                    <div className="col-sm-12 mt-4 bottom-stripe bottom-stripe-mt" />

                    <div className="top-stripe row align-items-center no-gutters">
                        <div className="col">
                            <p className="pl-3 m-0 font-weight-bold">
                                View Asset Documents
                            </p>
                        </div>
                        <div className="col">{getDocs()}</div>
                    </div>

                    <div className="col-sm-12 mt-4 bottom-stripe bottom-stripe-mt" />

                    <p className="label pl-3 mt-3">
                        Was Maintenance completed? *
                    </p>

                    <div className="row justify-content-around">
                        <div className="col-auto text-center">
                            <div>Yes</div>
                            <label className="q-radio-inline q-radio">
                                <input type="radio" onChange={() => { setIsComplete(true); }} name="completed" />
                                <span className="q-checkmark" />
                            </label>
                        </div>
                        <div className="col-auto text-center">
                            <div>No</div>
                            <label className="q-radio-inline q-radio">
                                <input type="radio" onChange={() => { setIsComplete(false); }} name="completed" />
                                <span className="q-checkmark" />
                            </label>
                        </div>
                    </div>

                    <div className="col-sm-12 mt-4 bottom-stripe bottom-stripe-mt" />

                    <div className="col-sm-12" style={{ paddingTop: "10px" }}>
                        <p className="label">What was done / Additional Info</p>

                        <div className="ml-2 mb-3">
                            <textarea cols={10} rows={5} value={additionalInfo}
                                onChange={(e) => { setAdditionalInfo(e.target.value); }}
                                className="form-control form-textarea"
                            />
                        </div>
                    </div>

                    <div className="col-sm-12 bottom-stripe bottom-stripe-mt" />

                    <div className="col-sm-12 mt-3 image-front-m">
                        <p className="label">Next maintenance date *</p>
                        <div className="mt-1 mb-4">
                            <input type="date" disabled value={nextDate.format("YYYY-MM-DD")}
                                className="form-control" />
                        </div>
                    </div>
                </div>
            </IonContent>

            <IonFooter>
                <div className="footer">
                    <div className="row">
                        <div className="col-12 footer-col-center">
                            <span>{isFinish ?
                                (<button onClick={() => { uploadImageToFirebase("main", decodedMainFirbaseImage) }}
                                    className="center-x success">
                                    <i className="fas fa-check"></i>
                                </button>) :
                                (<p className="center-x">
                                    <i className="fas fa-times"></i>
                                </p>)
                            }</span>
                        </div>
                    </div>
                </div>
            </IonFooter>
        </IonPage>
    );
};

export default AssetMaintenance;
