import React from 'react';
import { Link } from 'react-router-dom';
import { getBaseURL } from '../services/HelperMethod';

interface ListItemProps {
    to: string,
    imageSrc?: string,
    children?: React.ReactNode
}

const ListItem = (props: ListItemProps) => {
    return (
        <Link to={props.to} className="col-12 pci-verification store-data">
            <div className="row align-items-center">
                <div className="col-8 table-view-description-large">
                    {props.children}
                </div>
                <div className="col-4 item-image" style={{ display: "flex", justifyContent: "flex-end" }}>
                    {getImage()}
                </div>
            </div>
        </Link>
    )

    function getImage() {
        let imgSrc = './assets/icon/app/home-icon.png'
        
        if (props.imageSrc) {
            imgSrc = props.imageSrc;
        }
        return <div className="list-image" style={{backgroundImage:`url(${imgSrc})`}}></div> 
    }
}

export default ListItem;