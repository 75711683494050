import React, { useState } from "react";

export const LoadingContext = React.createContext(false);
export const MessageCountContext = React.createContext([]);

const Store = ({ children }) => {
  // const [isLoading, setIsLoading] = useState(false);
  const [messageCount, setMessageCount] = useState(0);

  return (
    <LoadingContext.Provider value={false}>
      <MessageCountContext.Provider value={[messageCount, setMessageCount]}>
        {children}
      </MessageCountContext.Provider>
    </LoadingContext.Provider>
  );
};

export default Store;
