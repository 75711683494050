/* eslint-disable */

import React, { useState, useEffect, useContext, useRef } from "react";
import { IonHeader, IonPage, IonContent } from "@ionic/react";
import TitleBar from "../components/TitleBar";
import Footer from "../components/Footer";
import ApiService from "../services/ApiService";
import { showNotification } from "../services/HelperMethod";
import moment from "moment";
import { MessageCountContext } from "../store/Store";
import LoadingIndicator from "../components/LoadingIndicator";

const MessagesFull = (props: any) => {
  const apiService = ApiService();

  const [message, setMessage] = useState("");
  const [messages, setMessages] = useState({} as any);
  const [isLoading, setIsLoading] = useState(false);
  const [messageCount, setMessageCount] = useContext(MessageCountContext);

  const chatMessageEndRef = useRef(null);

  useEffect(() => {
    getMessages();
    startCheckMessage();
  }, []);

  function scrollToBottom() {
    chatMessageEndRef?.current?.scrollIntoView(false);
  }

  function startCheckMessage() {
    apiService.checkNewMessages().then((data: any) => {
      data = data.data;

      if (data.length > 0) {
        getMessages(true);
      }

      setTimeout(() => {
        if (chatMessageEndRef.current) {
          startCheckMessage();
        }
      }, 2000);
    });
  }

  function getMessages(silent = false) {
    if (!silent) {
      setIsLoading(true);
    }

    apiService.getMessages(props?.match?.params?.receiverId).then((data: any) => {
      data = data.data;

      setMessages(data);

      setIsLoading(false);

      scrollToBottom();

      apiService.checkNewMessages().then((data: any) => {
        data = data.data;
        setMessageCount(data.length);
      });
    });
  }

  function sendMessage() {
    setIsLoading(true);

    if (message) {

      var data = { message: message, receiverId: props?.match?.params?.receiverId };

      apiService.sendMessage(data).then((data: any) => {
        data = data.data;

        if (data.result === true) {
          setMessage("");
          getMessages();
        } else {
          showNotification(data.errorExplanation);
        }
      }).catch((err: any) => {
        setIsLoading(false);
        showNotification("Network Error, try again!");
      });
    }
  }
  return (
    <IonPage className="safe-view">
      <IonHeader>
        <TitleBar title="Messages" />
      </IonHeader>

      <IonContent>
        <div>
          <LoadingIndicator isLoading={isLoading} />
        </div>
        <div className="full-message-wrap">
          <div className="message-wrap">
            <div className="messages">
              {messages.messages?.map((msg: any) => {
                let classNames = "msg";

                if (msg.senderId == messages.uid) {
                  classNames += " me";
                }

                return (
                  <div key={msg.id} className={classNames}>
                    <div className="sender badge">{msg.sender.firstName}</div>
                    <div className="msg-body">{msg.message}</div>
                    <div className="time badge">
                      {moment(msg.sendDate).local().format("MMM Do, YY h:mm a")}
                    </div>
                  </div>
                );
              })}
              <div ref={chatMessageEndRef}></div>
            </div>
          </div>
          {/*<div className="input-area">
            <div className="row row justify-content-center align-items-center">
              <div className="col">
                <input type="text" value={message} onChange={(e) => { setMessage(e.target.value); }} className="textarea" />
              </div>
              <div className="col-auto">
                <button type="button" onClick={() => { sendMessage(); }} className="chat-send">
                  <i className="fas fa-paper-plane"></i>
                </button>
              </div>
            </div>
            </div>*/}
        </div>
      </IonContent>
      
      <Footer />
    </IonPage>
  );
};

export default MessagesFull;
