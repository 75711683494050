/* eslint-disable */

import React from "react";
import { toast } from "react-toastify";
import NotificationService from "./NotificationService";
import { isPlatform } from "@ionic/react";

function formatTime(datetime) {
  let fdtp = new Date(datetime).toLocaleTimeString();
  return <span>{fdtp}</span>;
}

function formatDate(datetime) {
  let fdtp = new Date(datetime).toDateString();
  return <span>{fdtp}</span>;
}

function getImageURL(imgURL) {
  if (imgURL?.search("add-image.png") && isURL(imgURL)) {
    return imgURL;
  } else if (!imgURL) {
    return "/assets/icon/app/add-image.png";
  } else {
    return "/assets/icon/app/add-image.png";
  }
}

function isURL(str) {
  var pattern = new RegExp(
    "^(https?:\\/\\/)?" + // protocol
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|" + // domain name
      "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
      "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
      "(\\#[-a-z\\d_]*)?$",
    "i"
  ); // fragment locator
  return pattern.test(str);
}

const getBaseURL = (appendURL = "") => {
  let url = process.env.REACT_APP_BASE_URL + "/" + appendURL;
  url = url.replace(/([^:])(\/{2,})/g, "$1/"); // remove double //
  return url;
};

const showNotification = (message) => {
  if (message) {
    toast(message);
  }
};

const showValidationErrors = (error) => {
  let err = error?.response?.data?.errors;
  if (!err) {
    showNotification("Network Error! try again");
    return;
  }

  let keys = Object.keys(err);
  if (keys) {
    keys.forEach((er) => {
      showNotification(err[er][0]);
    });
  }
};
function uuidv4() {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c == "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

function getUID() {
  var uid = localStorage.getItem("uuid");
  if (!uid) {
    var uid = uuidv4();
    localStorage.setItem("uuid", uid);
  }

  return uid;
}

export function getImage(img, overrideIcon = null) {
  let imgSrc = "./assets/icon/app/home-icon.png";

  if (overrideIcon) {
    imgSrc = overrideIcon;
  }

  if (img) {
    imgSrc = img;
  }

  if (isBase64(img)) {
    imgSrc = img;
  }

  return (
    <div
      style={{ backgroundImage: `url(${imgSrc})` }}
      className="general-image"
    ></div>
  );
}

function handleErrors(result, props, setIsLoading) {
  setIsLoading(false);
  if (result.errorType == "validate_2fa") {
    localStorage.setItem("jwt", result.additionalData.temporaryToken);
    localStorage.setItem("email", result.additionalData.userEmail);
    localStorage.setItem("phone", result.additionalData.userPhone);
    props.history.push("/validate2FAMethods");
  }

  if (result.errorType != "validate_2fa") {
    showNotification(result.errorExplanation);
  }
}

export function afterLogin(data, setIsLoading, props) {
  if (data.errorType) {
    handleErrors(data, props, setIsLoading);
    return;
  }

  localStorage.setItem("jwt", data.token);
  localStorage.setItem("userClaims", data.userClaims);

  const notificationService = new NotificationService();

  setIsLoading(true);

  props.history.push("/home");

  if (isPlatform("mobileweb")) {
    setIsLoading(false);
    props.history.push("/home");
  }
}

function isBase64(str) {
  if (str && (str === "" || str.trim() === "")) {
    return false;
  }

  try {
    str = str.replace("data:image/png;base64,", "");
    return btoa(atob(str)) == str;
  } catch (err) {
    return false;
  }
}

const getClaims = () => {
  var strUser = localStorage.getItem("userClaims");
  return strUser;
};

export function checkClaim(userClaim) {
  let claims = getClaims();
  let isClaimIn = claims?.split(",")?.find((claim) => {
    return claim == userClaim;
  });

  return isClaimIn;
}

export {
  formatTime,
  formatDate,
  getBaseURL,
  showNotification,
  showValidationErrors,
  getUID,
  getImageURL,
};
