/* eslint-disable */

import { IonPage, IonContent, IonHeader } from "@ionic/react";
import { Link } from "react-router-dom";
import React, { Fragment, useEffect, useState } from "react";
import TitleBar from "../../../components/TitleBar";
import Footer from "../../../components/Footer";
import ApiService from "../../../services/ApiService";
import { formatDate, getImage } from "../../../services/HelperMethod";
import moment from "moment";
import { Geolocation } from "@ionic-native/geolocation/";
import LoadingIndicator from "../../../components/LoadingIndicator";
import { showNotification } from "../../../services/HelperMethod";

const Verifications = (props) => {
  const apiService = ApiService();

  const [verifications, setVerifications] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [, setIsMapLoading] = useState(true);

  let originalVerifications = [];

  let currentLat;
  let currentLong;
  let isGettingGeo = false;

  function getPlusButtonUI() {
    // return (
    //   <div className="plus-button row justify-content-center">
    //     <Link to={"/createVerification/" + props?.match?.params?.storeId}>
    //       <p className="center-x success">
    //         <i className="fas fa-plus"></i>
    //       </p>
    //     </Link>
    //   </div>
    // );
  }

  useEffect(() => {
    setIsLoading(true);

    apiService.getVerifications().then((verificationData: any) => {
      verificationData = verificationData.data;

      setVerifications(verificationData);
      originalVerifications = verificationData;

      getGeo(verificationData);
      setIsLoading(false);

      setInterval(() => {
        Geolocation.getCurrentPosition().then((c) => {
          if (
            currentLat == c?.coords?.latitude &&
            currentLong == c?.coords?.longitude
          ) {
            return;
          }

          if (!isGettingGeo) {
            isGettingGeo = true;

            getGeo(originalVerifications, () => {
              isGettingGeo = false;
            });
          }
        });
      }, 5000);
    });
  }, []);

  function getGeo(
    verifications,
    completeCallBack: CallableFunction = () => {}
  ) {
    verifications.forEach((verification) => {
      const verificationDate = moment(
        verification.verificationInfo.dueDate
      ).local();

      const eightDateAfterFromTodayDate = moment().add(7, "d");

      if (verificationDate.isBefore(eightDateAfterFromTodayDate, "d")) {
        Geolocation.getCurrentPosition()
          .then(async (c) => {
            currentLat = c?.coords?.latitude;
            currentLong = c?.coords?.longitude;

            if (currentLat && currentLong) {
              const targetLat = verification.lane.lat;
              const targetLong = verification.lane.long;

              apiService
                .getGeoData({
                  OriginLat: currentLat,
                  OriginLong: currentLong,
                  TargetLat: targetLat,
                  TargeLong: targetLong,
                })
                .then((geoData: any) => {
                  geoData = geoData.data;

                  if (!geoData.errorType) {
                    const findVerificationIndex = verifications.findIndex(
                      (v) => v.lane.id == verification.lane.id
                    );
                    if (findVerificationIndex >= 0) {
                      verifications[findVerificationIndex].geoData = geoData;
                    }

                    setIsMapLoading(true);
                    setVerifications(verifications);
                    setIsMapLoading(false);
                    completeCallBack();
                  }
                })
                .catch((err) => {
                  console.error(err);
                  setIsLoading(false);
                  completeCallBack();
                });
            }
          })
          .catch((err) => {
            console.error(err);
            setIsLoading(false);
            setVerifications(verifications);
          });
      }
    });
  }

  function geoDataUI(v) {
    if (!v?.geoData?.destination_addresses[0]) {
      return (
        <div className="badge text-secondary pr-2">
          Fetching location data...
        </div>
      );
    }

    if (v.geoData?.rows?.[0].elements?.[0]?.status == "ZERO_RESULTS") {
      return (
        <div className="no-distance-result">
          <a
            target="_blank"
            href={`https://map.google.com/maps?q=${v.lane.lat},${v.lane.long}`}
          >
            <div className="badge text-primary pr-2">
              No distance result, open Map
            </div>
            <i className="fas text-primary fa-map-marker-alt" />
          </a>
        </div>
      );
    }

    return (
      <div className="geo-data">
        <div className="row justify-content-between align-items-center">
          <div className="col-auto">
            <div className="lane-location">
              <div className="badge text-secondary pl-0">Destination:</div>
              <br />
              {v.geoData?.destination_addresses?.[0]}
            </div>

            <div className="row align-items-center justify-content-start">
              <div className="col-auto">
                <div className="lane-distance">
                  <div className="badge text-secondary pl-0">Distance:</div>
                  <br />
                  <span>
                    {v.geoData?.rows?.[0].elements?.[0]?.distance?.text}
                  </span>
                </div>
              </div>
              <div className="col-auto">
                <div className="badge text-secondary pl-0">Duration:</div>
                <br />
                <div className="lane-duration">
                  {v.geoData?.rows?.[0].elements?.[0]?.duration?.text}
                </div>
              </div>
            </div>
          </div>
          <div className="col-auto">
            <div className="badge text-secondary pl-0">Open Map</div>
            <div className="text-center">
              <div className="lane-map m-auto">
                <a
                  target="_blank"
                  href={`https://map.google.com/maps?q=${v.lane.lat},${v.lane.long}`}
                >
                  <i className="fas fa-map-marker-alt" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  function getByDay(from, to, v) {
    if (props?.match?.params?.storeId != v.store.id) {
      return <></>;
    }

    const dueDate = moment(v.verificationInfo.dueDate).local();

    const fromDate = moment().add(from, "days");
    const toDate = moment().add(to, "days");

    let isExpired = false;
    let expiredUI = <Fragment />;

    let soonUI = <Fragment></Fragment>;
    let isSoon = false;

    if (dueDate.isBefore(moment(), "day")) {
      isExpired = true;
      expiredUI = (
        <span className="badge badge-danger expired-badge">Overdue</span>
      );
    }

    if (dueDate.isAfter(moment().add(2, "days"), "day")) {
      isSoon = true;
      soonUI = <span className="badge badge-info expired-badge">Upcoming</span>;
    }

    if (dueDate.isBetween(fromDate, toDate, "day")) {
      return (
        <div className="store-verification-item">
          {v.equipments.map((eq, i) => {
            return (
              <div
                onClick={() => {
                  props.history.push(
                    "/due/" + v.verificationInfo.id + "/" + eq.id
                  );
                }}
                key={i}
              >
                <div className="col">
                  <div>
                    <b>{v.lane.name}</b>{" "}
                    <p className="model-number-style"> • Model: {eq.model}</p>
                  </div>
                  <p className="due-date">
                    Due:{" "}
                    {formatDate(
                      moment(v.verificationInfo.dueDate).local().toDate()
                    )}
                  </p>
                </div>
              </div>
            );
          })}
        </div>
      );
    }

    return <></>;
  }

  return (
    <IonPage className="safe-view">
      <IonHeader>
        <TitleBar title="Store verifications" />
      </IonHeader>
      <IonContent>
        <div>
          <LoadingIndicator isLoading={isLoading} />
        </div>
        <div className="row store-verification-wrapper">
          <div className="col-12 mt-4 mb-3">
            <div className="due-text text-danger pl-2">Due today</div>
          </div>
          {verifications.map((v, i) => {
            return (
              <div key={i} className="col-12">
                {getByDay(-6, 1, v)}
              </div>
            );
          })}

          <div className="col-12 mt-4">
            <div className="due-text text-info pl-2">Due in 7 days</div>
          </div>
          {verifications.map((v, i) => {
            return (
              <div key={i} className="col-12">
                {getByDay(0, 9, v)}
              </div>
            );
          })}

          <div className="col-12 mt-4">
            <div className="due-text text-success pl-2">Due in 14 days</div>
          </div>
          {verifications.map((v, i) => {
            return (
              <div key={i} className="col-12">
                {getByDay(8, 16, v)}
              </div>
            );
          })}
        </div>

        {getPlusButtonUI()}
      </IonContent>
      <Footer />
    </IonPage>
  );
};

export default Verifications;
