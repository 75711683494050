/* eslint-disable */

import React, { useState, useEffect } from "react";
import { IonPage, IonContent } from "@ionic/react";
import ApiService from "../services/ApiService";
import { showNotification, getUID, afterLogin } from "../services/HelperMethod";
// import { GooglePlus } from "@ionic-native/google-plus";
import { UniqueDeviceID } from "@ionic-native/unique-device-id/";
import packageJson from "../../package.json";
import LoadingIndicator from "../components/LoadingIndicator";

const Login = (props) => {
    const apiService = ApiService();

    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    let isNotificationInit = false;

    useEffect(() => {
        let jwt = localStorage.getItem("jwt");

        if (jwt) {
            setIsLoading(true);

            apiService.checkJWT().then((data) => {
                setIsLoading(false);

                var notificationAction = localStorage.getItem("notificationAction");

                if (notificationAction == "message") {
                    localStorage.removeItem("notificationAction");
                    props.history.push("/message");
                } else {
                    props.history.push("/home");
                }
            }).catch((err) => {
                setIsLoading(false);
            });
        }
    }, []);

    function handleLogin() {
        if (!username || !password) {
            return;
        }

        setIsLoading(true);

        if (window.hasOwnProperty("cordova")) {
            UniqueDeviceID.get().then((deviceId) => {
                tryLogin(username, password, deviceId);
            }).catch((err) => {
                var deviceId = getUID();
                tryLogin(username, password, deviceId);
            });
        } else {
            var deviceId = getUID();

            setIsLoading(false);
            tryLogin(username, password, deviceId);
        }

        localStorage.setItem("deviceId", deviceId);
    }

    function tryLogin(username, password, deviceId) {
        apiService.authenticate(username, password, deviceId).then((data: any) => {
            data = data.data;
            afterLogin(data, setIsLoading, props);
        }).catch((error) => {
            setIsLoading(false);
            showNotification(error.message);
        })
    }

    return (
        <IonPage>
            <IonContent>
                <div className="login-wrap">
                    <LoadingIndicator isLoading={isLoading} />
                    <div className="logo">
                        <img src="./assets/images/pcid_logo.png" alt="pcid" className="login-logo" />
                    </div>

                    <div className="input-area">
                        <div className="">
                            <input autoCapitalize="off" className="form-control pcid-textbox" type="text" value={username}
                                onChange={(e) => { setUsername(e.target.value); }} placeholder="Username or Email" />
                        </div>

                        <div className="mt-4">
                            <input className="form-control pcid-textbox" type="password" value={password}
                                onChange={(e) => { setPassword(e.target.value); }} placeholder="Password" />
                        </div>
                    </div>

                    <div className="buttons-area">
                        <div className="login-button-wrap">
                            <button onClick={() => { handleLogin(); }} className="btn login-btn-gap btn-success btn-pcid">
                                LOG IN
                            </button>
                        </div>

                        <div className="login-button-wrap">
                            <div className="text-center mt-4">
                                <button onClick={() => { props.history.push("resetPassword"); }}
                                    className="pcid-link btn-link mt-2 mb-2 ">
                                    Forgot Password?
                                </button>
                            </div>
                        </div>
                    </div>

                    {/*<div className="buttons-area">
                        <div className="login-button-wrap">
                            <button className="btn btn-white sign-with-google" onClick={async () => { await signInGoogle(); }}>
                                <img className="mr-3 mb-1 mt-1" src="./assets/icon/app/google.png" alt="" width={20} height={20} />
                                Sign in google
                            </button>
                        </div>
    </div>*/}

                    <small className="text-right">
                        <div className="badge mr-3 mb-3 text-secondary">
                            V {packageJson.version}
                        </div>
                    </small>
                </div>
            </IonContent>
        </IonPage>
    );
};

export default Login;
