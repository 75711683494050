/* eslint-disable */

import React, { useEffect } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { IonApp } from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import Home from "./pages/Home";
import Messages from "./pages/Messages";
import MessagesFull from "./pages/MessagesFull";
import Login from "./pages/Login";
import Verifications from "./pages/InnerPages/Verifications/Verifications";
import VerificationForm from "./pages/InnerPages/Verifications/VerificationForm";
import ScheduledMaintenance from "./pages/InnerPages/Maintenance/ScheduledMaintenance";
import AssetMaintenance from "./pages/InnerPages/Maintenance/AssetMaintenance";
import Assets from "./pages/InnerPages/AssetManagement/Assets";
import Terminals from "./pages/InnerPages/Verifications/Terminals";
import CreateAsset from "./pages/CreateAsset";
import CreateTerminal from "./pages/CreateTerminal";
import Profile from "./pages/Profile";
import Validate2FA from "./pages/Validate2FA";
import Verification2FAMethods from "./pages/Verification2FAMethods";
import AssetStores from "./pages/InnerPages/AssetManagement/AssetStores";
import VerificationStores from "./pages/InnerPages/Verifications/VerificationStores";
import ResetPassword from "./pages/ResetPassword";
import { ToastContainer } from "react-toastify";
import Store from "./store/Store";

import "@ionic/react/css/core.css";
import "@ionic/react/css/normalize.css";
import "./assets/scss/styles.scss";
import "react-toastify/dist/ReactToastify.css";
import { createTracing } from "trace_events";

const App = (props) => {
  return (
    <Store>
      <IonApp>
        <ToastContainer />
        <IonReactRouter>
          <Switch>
            <Route path="/login" component={Login} />
            <Route path="/home" component={Home} />
            <Route
              path="/storeverification/:storeId"
              component={Verifications}
              exact
            />
			<Route
              path="/due/:laneId/:deviceId"
              component={VerificationForm}
              exact={true}
            />
            <Route
              path="/due/:laneId/:deviceId/:quickScan"
              component={VerificationForm}
              exact={true}
            />
            <Route
              path="/storedepartment/:storeId"
              component={ScheduledMaintenance}
              exact={true}
            />
            <Route
              path="/assetmaintenance/:assetId"
              component={AssetMaintenance}
              exact={true}
            />
            <Route path="/assets/:storeId/" component={Assets} exact={true} />
            <Route
              path="/terminals/:storeId/"
              component={Terminals}
              exact={true}
            />
            <Route
              path="/verification/:storeId/"
              component={Assets}
              exact={true}
            />
            <Route path="/assetStore" component={AssetStores} exact={true} />
            <Route
              path="/verificationStore"
              component={VerificationStores}
              exact={true}
            />

            <Route
              path="/createasset/:storeId"
              component={CreateAsset}
              exact={true}
            />
            <Route
              path="/updateAsset/:storeId/:assetId"
              component={CreateAsset}
              exact={true}
            />

            <Route
              path="/createTerminal/:storeId"
              component={CreateTerminal}
              exact={true}
            />
            <Route
              path="/updateTerminal/:storeId/:assetId"
              component={CreateTerminal}
              exact={true}
            />
            <Route path="/message" component={Messages} exact={true} />
            <Route
              path="/messageBody/:receiverId"
              component={MessagesFull}
              exact={true}
            />
            <Route path="/profile" component={Profile} exact={true} />
            <Route path="/validate2FA/:method" component={Validate2FA} />
            <Route
              path="/validate2FAMethods"
              component={Verification2FAMethods}
              exact={true}
            />
            <Route
              path="/resetPassword"
              component={ResetPassword}
              exact={true}
            />
            <Route
              path="/"
              render={() => <Redirect to="/login" />}
              exact={true}
            />
          </Switch>
        </IonReactRouter>
      </IonApp>
    </Store>
  );
};

export default App;
