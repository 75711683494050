/* eslint-disable */

import React, { useEffect, useState, Fragment } from "react";
import { Link } from "react-router-dom";
import { IonPage, IonContent, IonHeader } from "@ionic/react";
import TitleBar from "../../../components/TitleBar";
import ApiService from "../../../services/ApiService";
import moment from "moment";
import { checkClaim, formatDate, getImage } from "../../../services/HelperMethod";
import Footer from "../../../components/Footer";
import LoadingIndicator from "../../../components/LoadingIndicator";

const ScheduledMaintenance = (props) => {
    const apiService = ApiService();

    const [assets, setAssets] = useState([]);
    const [isLoading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);

        apiService.getAssetsByMaintenance(props?.match?.params?.storeId).then((data: any) => {
            data = data.data;

            setAssets(data);
            setLoading(false);
        }).catch(() => {
            setLoading(false);
        });
    }, []);

    function maintenance(from, to, assetMaintenance) {
        var fromDate = moment().add(from, "days");
        var toDate = moment().add(to, "days");

        let expiredUI = <></>;
        let isExpired = false;

        let momentDueDate = moment(assetMaintenance.dueDate).local();

        if (!moment(assetMaintenance.dueDate).local().isBetween(fromDate, toDate, "day")) {
            return <Fragment />;
        }

        return (
            <div className="item-navigation-link"
                onClick={() => { props.history.push("/assetmaintenance/" + assetMaintenance.asset.id); }}>
                <div className="row justify-content-center align-items-center">
                    <div className="col-auto">
                        {getImage(assetMaintenance.asset.imageURL)}
                    </div>
                    <div className="col">
                        <p className="model-number">
                            <span className="item-nav-label">
                                Model Number:{" "}
                                <span className="ml-1">
                                    {assetMaintenance.asset.modelNumber}
                                </span>
                            </span>
                        </p>
                        <p className="model-number">
                            <span className="item-nav-label">
                                Serial Number:{" "}
                                <span className="ml-2">
                                    {assetMaintenance.asset.serialNumber}
                                </span>
                            </span>
                        </p>
                        <p className="model-number">
                            <span className="item-nav-label">
                                Description:{" "}
                                <span className="ml-2">
                                    {assetMaintenance.asset.description}
                                </span>
                            </span>
                        </p>
                        <div className="badge pl-0">
                            {formatDate(moment(assetMaintenance.dueDate).local().toDate())}
                        </div>
                    </div>
                </div>
                {expiredUI}
            </div>
        );
    }

    function getViewAllAssetButton() {
        if (checkClaim("Manager")) {
            return (
                <div className="asset-btn-wrap">
                    <Link to={"/assets/" + props?.match?.params?.storeId} className="btn view-asset-btn">View all assets</Link>
                </div>
            );
        }

        return <Fragment />;
    }

    return (
        <IonPage className="safe-view">
            <IonHeader>
                <TitleBar title="Schedule Maintenance" />
            </IonHeader>

            <IonContent className="maintenance">
                <LoadingIndicator isLoading={isLoading} />
                {getViewAllAssetButton()}
                <div className="row">
                    <div className="col-12 bottom-stripe">
                        <p className="text-danger font-weight-bold ml-4 mt-3">
                            Due today
                        </p>
                    </div>
                </div>

                <div className="row">
                    {assets.map((asset, i) => {
                        return (
                            <div key={i} className="col-12">
                                {maintenance(-32, 1, asset)}
                            </div>
                        );
                    })}
                </div>

                <div className="row">
                    <div className="col-12 bottom-stripe">
                        <p className="text-info font-weight-bold ml-4 mt-3">
                            Due in 7 days
                        </p>
                    </div>
                </div>

                <div className="row">
                    {assets.map((asset, i) => {
                        return (
                            <div key={i} className="col-12">
                                {maintenance(0, 9, asset)}
                            </div>
                        );
                    })}
                </div>

                <div className="row">
                    <div className="col-12 bottom-stripe">
                        <p className="text-success font-weight-bold ml-4 mt-3">
                            Due in 14 days
                        </p>
                    </div>
                </div>

                <div className="row">
                    {assets.map((asset, i) => {
                        return (
                            <div key={i} className="col-12">
                                {maintenance(8, 16, asset)}
                            </div>
                        );
                    })}
                </div>
            </IonContent>

            <Footer />
        </IonPage>
    );
};

export default ScheduledMaintenance;
