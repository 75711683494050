/* eslint-disable */

import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { IonPage, IonContent, IonHeader } from "@ionic/react";
import Footer from "../../../components/Footer";
import ApiService from "../../../services/ApiService";
import TitleBar from "../../../components/TitleBar";
import { getImage } from "../../../services/HelperMethod";
import LoadingIndicator from "../../../components/LoadingIndicator";
import ListItem from "../../../components/ListItem";

const Assets = (props: any) => {
  const apiService = ApiService();

  const [assets, setAssets] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [storeName, setStoreName] = useState("");

  useEffect(() => {
    setLoading(true);

    apiService.getStore(props?.match?.params?.storeId).then((data: any) => {
      data = data.data;

      if (!data.errorType) {
        setStoreName(data.name);
      }
    });

    apiService.getAllAssets(props?.match?.params?.storeId).then((data: any) => {
      data = data.data;

      setAssets(data);

      setLoading(false);
    });
  }, []);

  function getPlusButtonUI() {
    return (
      <div className="plus-button row justify-content-center">
        <Link to={"/createasset/" + props?.match?.params?.storeId}>
          <p className="center-x success">
            <i className="fas fa-plus"></i>
          </p>
        </Link>
      </div>
    );
  }

  return (
    <IonPage className="safe-view">
      <IonHeader>
        <TitleBar title={"Assets in " + storeName} />
      </IonHeader>

      <IonContent>
        <div className="row">
          <LoadingIndicator isLoading={isLoading} />
          <div className="col-12 mb-2 mt-2">
            {assets.map((asset) => {
              console.log(asset);
              return (
                <ListItem
                  key={asset.id}
                  to={
                    "/updateAsset/" +
                    props?.match?.params?.storeId +
                    "/" +
                    asset.id
                  }
                  imageSrc={asset.imageURL}
                >
                  <div className="row">
                    <div className="col-12">
                      <div className="store-title pl-3">
                        <span className="float-left store-title-text">
                          {asset.description}
                        </span>
                      </div>
                    </div>
                    <div className="col-12">
                      <p className="store-description pl-3">
                        Model Number: {asset.modelNumber}
                      </p>
                    </div>
                  </div>
                </ListItem>
              );
            })}
          </div>
        </div>

        {getPlusButtonUI()}
      </IonContent>

      <Footer />
    </IonPage>
  );
};

export default Assets;
