/* eslint-disable */

import React, { useState } from "react";
import { IonPage, IonContent } from "@ionic/react";
import ApiService from "../services/ApiService";
import { showNotification, showValidationErrors } from "../services/HelperMethod";
import LoadingIndicator from "../components/LoadingIndicator";

const ResetPassword = (props) => {
    const apiService = ApiService();

    const [isEmailSent, setIsEmailSent] = useState(false);
    const [email, setEmail] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    function sendResetPassword() {
        setIsLoading(true);

        apiService.requestResetPassword(email).then((data: any) => {
            data = data.data;

            setIsLoading(false);

            if (data.errorType) {
                showNotification(data.errorExplanation);
                return;
            }

            setIsEmailSent(true);
            showNotification(`Email has sent to reset your password`);

            props.history.push("/login");
        }).catch((err) => {
            setIsLoading(false);
            showValidationErrors(err);
        });
    }

    function generateUI() {
        if (isEmailSent) {
            return (
                <div className="col-12">
                    <span>
                        Email has sent to {email} to reset your password
                    </span>
                </div>
            );
        }

        return (
            <div className="col-12">
                <input autoCapitalize="off" className="form-control pcid-textbox" type="text"
                    value={email} onChange={(e) => { setEmail(e.target.value); }} placeholder="your@email.com" />
                <button onClick={() => { sendResetPassword(); }} className="btn mt-5 btn-success btn-pcid">
                    Reset Password
                </button>
            </div>
        );
    }

    return (
        <IonPage className="safe-view">

            <IonContent>
                <div className="login-wrap">
                    <LoadingIndicator isLoading={isLoading} />
                    <div className="logo">
                        <img src="./assets/images/pcid_logo.png" alt="pcid" className="login-logo" />
                        <div className="p-2 mt-2 text-center text-uppercase font-weight-bold small text-secondary">
                            <p>Reset Password</p>
                        </div>
                    </div>

                    <div className="input-area">
                        <div className="row mt-5">{generateUI()}</div>
                    </div>
                    <div className="input-area"></div>
                </div>
            </IonContent>

        </IonPage>
    );
};

export default ResetPassword;
