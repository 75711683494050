import React from 'react';
import { useHistory } from "react-router-dom";
import Logout from './Logout';

interface TitleBarProps {
    title?: string
}

const TitleBar = (props: TitleBarProps) => {
    let history = useHistory();

    return (
        <div className="nav-bar">
            <div className="row justify-content-start align-items-center">
                <div className="col-auto text-center">
                    <span onClick={() => { history.goBack(); }} className="title-back-link pl-3">
                        <i className="fas fa-chevron-left"></i>
                    </span>
                </div>
                <div className="col">
                    <p className="top-title">{props.title}</p>
                </div>
                <Logout />
            </div>
        </div>
    )
}

export default TitleBar