/* eslint-disable */

import axios from "axios";

const ApiService = () => {
  function authenticate(username, password, deviceId) {
    return ax().post("auth", { username, password, deviceId });
  }

  function handle2FA(code) {
    return ax().post("auth/validate2fa", { code });
  }

  function requestSendVerificationEmail() {
    return ax().post("auth/send2FAEmail");
  }

  function requestSendVerificationSMS(data) {
    return ax().post("auth/send2FASMS", data);
  }

  function logOut(callBack: CallableFunction) {
    localStorage.removeItem("jwt");
    callBack();
  }

  function registerDevices(data) {
    return ax().post("pushNotification/saveDevice", data);
  }

  function requestResetPassword(email) {
    return ax().post("auth/RequestResetPassword", { email });
  }

  function verifyLane(laneId) {
    return ax().post("verifications/verify/" + laneId);
  }

  function createMaintenanceLog(request) {
    return ax().post("maintenance/createLog", request);
  }

  function getAssetsByMaintenance(storeId) {
    return ax().get("/assets/byDepartment/" + storeId);
  }

  function getAllAssets(storeId) {
    return ax().get("/assets/store/" + storeId);
  }

  function getTerminalById(terminalId) {
    return ax().get("/store/terminalId/" + terminalId);
  }

  function getAllTerminals(storeId) {
    return ax().get("/store/terminal/" + storeId);
  }

  function getAssetsById(assetId) {
    return ax().get("/assets/" + assetId);
  }

  function getStore(storeId) {
    return ax().get("/store/" + storeId);
  }

  function createAsset(request) {
    return ax().post("/assets/", request);
  }

  function createEquipment(data) {
    return ax().post("/store", data);
  }

  function getVerificationEquipment(deviceId) {
    return ax().get("verifications/device/" + deviceId);
  }

  function getVerifications() {
    return ax().get(`verifications/`);
  }

  function createVerification(data) {
	return ax().post("verifications/", data);
	}

  function getVerificationByAsset(AssetTag) {
    return ax().get("/verifications/asset/" + AssetTag);
  }

  function getVerificationsStore() {
    return ax().get("store/verificationStore/");
  }

  function getOpenLanes(storeId) {
	return ax().get("store/openLanes/" + storeId);
  }

  function getUser() {
    return ax().get("/users/user");
  }

  function getChatUserList() {
    return ax().get("/shared/chat/users");
  }

  function sendMessage(data) {
    return ax().post("/shared/chat/send", data);
  }

  function getMessages(receiverId) {
    return ax().get("/shared/chat/messages/" + receiverId);
  }

  function checkNewMessages() {
    return ax().get("/shared/chat/checkUnreadMessages");
  }

  function updateUser(request) {
    return ax().patch("/users", request);
  }

  function getMaintenanceStore() {
    return ax().get("/maintenance/store");
  }

  function getDepartments(storeId) {
    return ax().get("/department/store/" + storeId);
  }

  function verifyDevice(data) {
    return ax().post("verifications/device", data);
  }

  function getGeoData(data) {
    return ax().post("verifications/getGeoData", data);
  }

  function getMaintenanceByAssetId(assetId) {
    return ax().get("/maintenance/asset/" + assetId);
  }

  function signInGoogle(token) {
    return ax().post("/auth/signinGoogle", token);
  }

  function checkJWT() {
    return ax().get("/auth/CheckJWT");
  }

  function ax() {
    let baseUlr = process.env.REACT_APP_BASE_URL;

    baseUlr = "https://uat-api.pceyed.net/";
    // baseUlr = "https://192.168.8.101:45455/";
    // baseUlr="https://localhost:44374/"
    // baseUlr = "https://pcid-server-gk4.conveyor.cloud/"

    const jwt = localStorage.getItem("jwt");

    return axios.create({
      baseURL: baseUlr,
      timeout: 90000,
      headers: {
        Authorization: `Bearer ${jwt}`,
        "Content-Type": "application/json",
      },
    });
  }

  return {
    ax,
    authenticate,
    signInGoogle,
    handle2FA,
    requestSendVerificationEmail,
    requestSendVerificationSMS,
    requestResetPassword,

    getVerificationsStore,
	createVerification,
	getOpenLanes,
    getVerificationByAsset,
    getStore,
    getVerifications,
    getVerificationEquipment,
    verifyLane,
	getTerminalById,
	
    getGeoData,

    getMaintenanceStore,
    getMaintenanceByAssetId,

    createAsset,
    createEquipment,
    getAssetsByMaintenance,
    getAssetsById,
    getAllAssets,
    getAllTerminals,

    createMaintenanceLog,
    getUser,
    updateUser,
    logOut,
    getDepartments,
    verifyDevice,

    getChatUserList,
    sendMessage,
    getMessages,
    checkNewMessages,

    registerDevices,
    checkJWT,
  };
};

(window as any).ApiService = ApiService();
export default ApiService;
