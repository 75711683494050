/* eslint-disable */

import React, { useEffect, useState } from "react";
import ListItem from "../../../components/ListItem";
import { Link } from "react-router-dom";
import { IonPage, IonContent, IonHeader } from "@ionic/react";
import Footer from "../../../components/Footer";
import ApiService from "../../../services/ApiService";
import TitleBar from "../../../components/TitleBar";
import { getImage } from "../../../services/HelperMethod";
import LoadingIndicator from "../../../components/LoadingIndicator";
import AssetMaintenance from "../Maintenance/AssetMaintenance";

const Terminals = (props: any) => {
  const apiService = ApiService();

  const [assets, setAssets] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [storeName, setStoreName] = useState("");

  useEffect(() => {
    setLoading(true);

    apiService.getStore(props?.match?.params?.storeId).then((data: any) => {
      data = data.data;

      if (!data.errorType) {
        setStoreName(data.name);
      }
    });

    apiService
      .getAllTerminals(props?.match?.params?.storeId)
      .then((data: any) => {
        data = data.data;
		console.log(data);
        setAssets(data);

        setLoading(false);
      });
  }, []);

  function getPlusButtonUI() {
    return (
      <div className="plus-button row justify-content-center">
        <Link to={"/createterminal/" + props?.match?.params?.storeId}>
          <p className="center-x success">
            <i className="fas fa-plus"></i>
          </p>
        </Link>
      </div>
    );
  }

  return (
    <IonPage className="safe-view">
      <IonHeader>
        <TitleBar title={"Terminals in " + storeName} />
      </IonHeader>

      <IonContent>
        <div className="row">
          <LoadingIndicator isLoading={isLoading} />
          <div className="col-12 mb-2 mt-2">
            {assets.map((asset) => {
              console.log(asset);
              return (
                  <ListItem
                    key={asset.id}
                    to={"/updateTerminal/" +
					props?.match?.params?.storeId +
					"/" +
					asset.id}
                    imageSrc={asset.frontImageURL}
                  >
                    <div className="row">
                      <div className="col-12">
                        <div className="store-title pl-3">
                          <span className="float-left store-title-text">
                            {asset.lane.name}
                          </span>
                        </div>
                      </div>
                      <div className="col-12">
                        <p className="store-description pl-3">
						Model Number: {asset.model}
                        </p>
                      </div>
                    </div>
                  </ListItem>
              );
            })}
          </div>
        </div>

        {getPlusButtonUI()}
      </IonContent>

      <Footer />
    </IonPage>
  );
};

export default Terminals;
