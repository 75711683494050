/* eslint-disable */

import React, { useState, useEffect } from "react";
import { IonPage, IonContent } from "@ionic/react";
import ApiService from "../services/ApiService";
import { showNotification, afterLogin } from "../services/HelperMethod";
import { SmsRetriever } from "@ionic-native/sms-retriever";
import LoadingIndicator from "../components/LoadingIndicator";

const Validate2FA = (props: any) => {
  const apiService = ApiService();

  const [authCode, setAuthCode] = useState("");
  const [isEmailSent, setIsEmailSent] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [appHash, setAppHash] = useState("");

  useEffect(() => {
    SmsRetriever.getAppHash().then((res: any) => {
      setAppHash(res);
    }).catch((error: any) => console.error(error));

    SmsRetriever.startWatching().then((res: any) => {
      let str = res.Message as string;
      let start = str.indexOf(":") + 2;

      setAuthCode(str.substr(start, 4));
    }).catch((error: any) => console.error(error));
  }, []);

  function handleValidate2FA() {
    setIsLoading(true);

    apiService.handle2FA(authCode).then((data: any) => {
      data = data.data;
      afterLogin(data, setIsLoading, props);
    }).catch((err: any) => {
      setIsLoading(false);
      showNotification("Network Error! try again");
    });
  }

  function sendVerificationEmail() {
    setIsLoading(true);

    if (props?.match?.params?.method == "email") {
      apiService.requestSendVerificationEmail().then((data: any) => {
        handleSent(data);
      }).catch((err) => {
        handleError();
      });
    }

    if (props?.match?.params?.method == "sms") {
      apiService.requestSendVerificationSMS({ DeviceCode: appHash }).then((data: any) => {
        handleSent(data);
      }).catch((err) => {
        handleError();
      });
    }
  }

  function handleError() {
    setIsLoading(false);
    showNotification("Network Error! try again");
  }

  function handleSent(data) {
    data = data.data;
    if (data === true) {
      setIsEmailSent(true);
    } else {
      showNotification(data.errorExplanation);
    }
    setIsLoading(false);
  }

  function emailUI() {
    var email = localStorage.getItem("email");
    var phone = localStorage.getItem("phone");

    if (!isEmailSent && props?.match?.params?.method == "sms") {
      return (
        <div className="input-area">
          <button onClick={() => { sendVerificationEmail(); }} className="btn btn-pcid">
            Send verification code to <br /> {phone}
          </button>
        </div>
      );
    }

    if (!isEmailSent && props?.match?.params?.method == "email") {
      return (
        <div className="input-area">
          <button onClick={() => { sendVerificationEmail(); }} className="btn btn-pcid">
            Send verification code to <br /> {email}
          </button>
        </div>
      );
    }

    return (
      <div className="input-area">
        <input autoCapitalize="off" className="form-control pcid-textbox" type="number" value={authCode}
          onChange={(e) => { setAuthCode(e.target.value); }} placeholder="Verification code" />
        <button onClick={() => { handleValidate2FA(); }} className="btn mt-5 btn-success btn-pcid">
          LOG IN
        </button>
      </div>
    );
  }

  return (
    <IonPage className="safe-view">

      <IonContent>
        <div className="login-wrap">
          <LoadingIndicator isLoading={isLoading} />
          <div className="mt-4 mb-4 text-center">
            <img src="./assets/images/pcid_logo.png" alt="pcid" className="login-logo" />
          </div>
          <div className="p-2 text-center text-uppercase font-weight-bold small text-secondary">
            <p>New Device Confirmation Required!</p>
          </div>

          <div className="row mt-5">{emailUI()}</div>
        </div>
      </IonContent>

    </IonPage>
  );
};

export default Validate2FA;
