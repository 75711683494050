import React from "react";
import { getBaseURL } from "../services/HelperMethod";
import { Link } from "react-router-dom";

interface MessageProps {
    img: string;
    userName: string;
    lastMessageText: string;
    lastMessageTime: string;
    isRead: boolean;
    userId: number;
}

const MessageListItem = (props: MessageProps) => {
    return (
        <Link className="p-2 d-block" to={"/messageBody/" + props.userId}>
            <div className="row pt-4 pb-2 align-items-center message">
                <div className="col-3">
                    <div className="profile-image" style={{ backgroundImage: `url(${getImage()})`, }} />
                </div>
                <div className="col p-0">
                    <p className="message-title">{props.userName}</p>
                    <p className="message-body">{props.lastMessageText}</p>
                </div>
                <div className="col-auto text-center">
                    <p className="message-time">{props.lastMessageTime}</p>
                    {props.isRead ? (<p className="message-online-status"></p>) :
                        (<></>)
                    }
                </div>
            </div>
        </Link>
    );

    function getImage() {
        let imgSrc = "./assets/icon/app/profile.png";

        if (props.img) {
            imgSrc = getBaseURL() + "/images/" + props.img;
        }

        return imgSrc;
    }
};

export default MessageListItem;
