import React from "react";
import ApiService from "../services/ApiService";
// import { GooglePlus } from "@ionic-native/google-plus";
import { useHistory } from "react-router-dom";

const Logout = () => {
    const apiService = ApiService();
    const history = useHistory();

    const logOutFn = () => {
        apiService.logOut(async () => {
            // try {
            //     await GooglePlus.logout();
            // } catch {}
            localStorage.removeItem('jwt');
            localStorage.removeItem('email');
            localStorage.removeItem('user');
            
            history.push("/login");
        });
    };

    return (
        <div className="col-3 text-center">
            <i className="fa fa-power-off footer-icon" onClick={logOutFn}></i>
        </div>
    );
};

export default Logout;
