/* eslint-disable */

import { IonContent, IonPage, IonHeader } from "@ionic/react";
import React, { useEffect, useState } from "react";
import Footer from "../components/Footer";
import ListItem from "../components/ListItem";
import LogoBar from "../components/LogoBar";
import ApiService from "../services/ApiService";
import moment from "moment";
import { BarcodeScanner } from "@ionic-native/barcode-scanner";
import { showNotification } from "../services/HelperMethod";
import LoadingIndicator from "../components/LoadingIndicator";

const Home = (props) => {
  const apiService = ApiService();

  const [verifications, setVerifications] = useState([]);
  const [maintenances, setMaintenance] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    getData();
    setIsLoading(false);
  }, []);

  const openScanner = async () => {
    try {
      const scan = await BarcodeScanner.scan({ resultDisplayDuration: 0 });

      apiService
        .getVerificationByAsset(scan.text)
        .then((data: any) => {
          data = data.data;
          console.log(data);
          try {
            props.history.push(
              "/due/" + data.laneVerificationID + "/" + data.assetId + "/1"
            );
          } catch (err) {
            props.history.push("/storedepartment/" + data.id);
          }
        })
        .catch((err) => {
          showNotification("Invalid Barcode Please Try Again");
        });
    } catch (err) {
      showNotification("Invalid Barcode Please Try Again");
    }
  };

  function getData() {
    apiService
      .getVerificationsStore()
      .then((data: any) => {
        data = data.data;
        setVerifications(data);
      })
      .catch((err) => {
        setIsLoading(false);
        showNotification(err.message);
      });

    apiService
      .getMaintenanceStore()
      .then((data: any) => {
        data = data.data;
        setMaintenance(data);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  }
  function getMaintenanceNotifications(maintenance) {
    let maintenanceDueDateInfo = { today: 0, sevenDays: 0, twelveDays: 0 };

    maintenance.maintenance?.forEach((v) => {
      let info = getDueInfo(v.dueDate);

      if (info == 1) {
        maintenanceDueDateInfo.today += 1;
      }
      if (info == 2) {
        maintenanceDueDateInfo.sevenDays += 1;
      }
      if (info == 3) {
        maintenanceDueDateInfo.twelveDays += 1;
      }
    });

    return notificationUI(maintenanceDueDateInfo);
  }

  function getVerificationNotifications(verifications) {
    let dueDatesInfo = { today: 0, sevenDays: 0, twelveDays: 0 };

    verifications.forEach((v) => {
      let info = getDueInfo(v.dueDate);

      if (info == 1) {
        dueDatesInfo.today += 1;
      }
      if (info == 2) {
        dueDatesInfo.sevenDays += 1;
      }
      if (info == 3) {
        dueDatesInfo.twelveDays += 1;
      }
    });

    return notificationUI(dueDatesInfo);
  }

  function notificationUI(dueDatesInfo) {
    let today = <></>;

    if (dueDatesInfo.today) {
      today = (
        <span className="store-count bg-style-2">{dueDatesInfo.today}</span>
      );
    }

    let sevenDays = <></>;

    if (dueDatesInfo.sevenDays) {
      sevenDays = (
        <span className="store-count bg-style-1">{dueDatesInfo.sevenDays}</span>
      );
    }

    let twelveDays = <></>;

    if (dueDatesInfo.twelveDays) {
      twelveDays = (
        <span className="store-count bg-style-3">
          {dueDatesInfo.twelveDays}
        </span>
      );
    }

    return (
      <div>
        {today}
        {sevenDays}
        {twelveDays}
      </div>
    );
  }

  function getDueInfo(dueDate) {
    dueDate = moment(dueDate).local();

    let fromDate = moment().add(-32, "day");
    let toDate = moment().add(1, "day");

    if (moment(dueDate).isBetween(fromDate, toDate, "day")) {
      return 1;
    }

    fromDate = moment().add(0, "day");
    toDate = moment().add(9, "day");

    if (moment(dueDate).isBetween(fromDate, toDate, "day")) {
      return 2;
    }

    fromDate = moment().add(8, "day");
    toDate = moment().add(16, "day");

    if (moment(dueDate).isBetween(fromDate, toDate, "day")) {
      return 3;
    }
  }

  function getVerificationTitle() {
    if (verifications.length) {
      return <h5 className="text-center mb-4 mt-4">Terminal Verifications</h5>;
    } else {
      return <></>;
    }
  }

  function getMaintenanceTitle() {
    if (maintenances.length) {
      return <h5 className="text-center mb-5 mt-5">Asset Maintenance</h5>;
    } else {
      return <></>;
    }
  }

  function getStoresUI() {
    if (!verifications.length) {
      return <></>;
    }
    return (
      <div className="col-12">
        {getVerificationTitle()}
        <div className="row">
          {verifications.map((verification) => {
            return (
              <ListItem
                key={verification.store.id}
                to={"/storeverification/" + verification.store.id}
                imageSrc={verification.store.imageURL}
              >
                <div className="row">
                  <div className="col-12">
                    <div className="store-title pl-3">
                      <span className="float-left store-title-text">
                        {verification.store.name}
                      </span>
                      {getVerificationNotifications(verification.verifications)}
                    </div>
                  </div>
                  <div className="col-12">
                    <p className="store-description pl-3">
                      {verification.store.address} &nbsp;|&nbsp;
                      {verification.store.city} &nbsp;|&nbsp;
                      {verification.store.state}
                    </p>
                  </div>
                </div>
              </ListItem>
            );
          })}
        </div>
      </div>
    );
  }

  return (
    <IonPage className="safe-view">
      <IonHeader>
        <LogoBar />
      </IonHeader>

      <IonContent>
        <div className="row min-height-100">
          <LoadingIndicator isLoading={isLoading} />
          <div className="quickBox">
            <button
              className="quickStart"
              onClick={() => {
                openScanner();
              }}
            >
              <img
                src="./assets/icon/app/ScanBarcode.svg"
                alt="scan"
                className="quickScanner"
              />
              Quick Start
            </button>
          </div>

          {getStoresUI()}

          <div className="col-12">
            {getMaintenanceTitle()}
            <div className="row mb-5">
              {maintenances.map((maintenance) => {
                return (
                  <ListItem
                    key={maintenance.store.id}
                    to={"/storedepartment/" + maintenance.store.id}
                    imageSrc={maintenance.store.imageURL}
                  >
                    <div className="row">
                      <div className="col-12">
                        <p className="store-title pl-3">
                          <span className="float-left store-title-text">
                            {maintenance.store.name}
                          </span>
                        </p>
                        {getMaintenanceNotifications(maintenance)}
                      </div>
                      <div className="col-12">
                        <p className="store-description pl-3">
                          {maintenance.store.address} &nbsp;|&nbsp;
                          {maintenance.store.city} &nbsp;|&nbsp;
                          {maintenance.store.state}
                        </p>
                      </div>
                    </div>
                  </ListItem>
                );
              })}
            </div>
          </div>
        </div>
      </IonContent>

      <Footer />
    </IonPage>
  );
};

export default Home;
