/* eslint-disable */

import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { IonPage, IonContent, IonHeader } from "@ionic/react";
import Footer from "../components/Footer";
import TitleBar from "../components/TitleBar";
import MessageListItem from "../components/MessageListItem";
import ApiService from "../services/ApiService";
import { showNotification } from "../services/HelperMethod";
import moment from "moment";
import LoadingIndicator from "../components/LoadingIndicator";

//import { Plugins, PushNotification, PushNotificationToken, PushNotificationActionPerformed, } from "@capacitor/core";
//const { PushNotifications } = Plugins;

const Messages = (props) => {
    const apiService = ApiService();

    const [users, setUsers] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    let isUnmounted = false;

    useEffect(() => {
        getUsersList();
        startCheckMessage();

        // PushNotifications.addListener(
        //   "pushNotificationReceived",
        //   (notification: PushNotification) => {
        //     if (!isUnmounted) {
        //       startCheckMessage();
        //     }
        //   }
        // );

        return () => {
            isUnmounted = true;
        };
    }, []);

    function startCheckMessage() {
        apiService.checkNewMessages().then((data: any) => {
            data = data.data;
            if (data.length > 0) {
                getUsersList(true);
            }

            setTimeout(() => {
                if (!isUnmounted) {
                    startCheckMessage();
                }
            }, 5000);
        });
    }

    function getUsersList(isSilent = false) {
        if (!isSilent) {
            setIsLoading(true);
        }

        apiService.getChatUserList().then((data: any) => {
            data = data.data;

            setUsers(data);

            setIsLoading(false);
        }).catch(() => {
            showNotification("Network error! try again");
            setIsLoading(false);
        });
    }

    function checkIsRead(user) {
        if (!user.message) {
            return false;
        }

        if (user.message?.senderId == user.uid) {
            return false;
        }

        return !user.message?.isSenderRead;
    }

    return (
        <IonPage className="safe-view">
            <IonHeader>
                <TitleBar title="Messages" />
            </IonHeader>

            <IonContent>
                <div className="message-user-wrap">
                    <LoadingIndicator isLoading={isLoading} />
                    {users.map((user) => {
                        return (
                            <MessageListItem userId={user.user.id} key={user.user.id} img={user.user.imageURL}
                                userName={user.user.firstName + " " + user.user.lastName}
                                lastMessageText={user.message?.message}
                                lastMessageTime={user.message?.sendDate ?
                                    moment(user.message?.sendDate).local().format("MMM Do, YY h:mm a") :
                                    ""
                                }
                                isRead={checkIsRead(user)} />
                        );
                    })}
                </div>
            </IonContent>
            
            <Footer />
        </IonPage>
    );
};

export default Messages;
