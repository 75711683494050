import React from "react";
import Logout from "./Logout";

const LogoBar = () => {
  return (
    <nav className="row align-items-center pt-3 pb-3 bg-light">
      <div className="col offset-3 text-center">
        <img className="pci-verification-pcid-logo" src="./assets/images/pcid_logo.png" alt="pcid" width="120" />
      </div>
      <Logout />
    </nav>
  );
};

export default LogoBar;
