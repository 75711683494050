import React from 'react';

interface LoadingIndicatorProps {
    isLoading?: boolean,
}

const LoadingIndicator = (props: LoadingIndicatorProps) => {
    function loading() {
        if (props.isLoading) {
            return (
                <div className="loading-indicator">
                    <p><i className="fas fa-circle-notch fa-spin icon"></i></p>
                </div>
            )
        }

        return <></>;
    }

    return loading();
}

export default LoadingIndicator;