/* eslint-disable */

import React, { useState, useEffect } from "react";
import { IonPage, IonContent, IonHeader, IonFooter } from "@ionic/react";
import TitleBar from "../components/TitleBar";
import ApiService from "../services/ApiService";
import { BarcodeScanner } from "@ionic-native/barcode-scanner";
import moment from "moment";
import { Camera, CameraOptions } from "@ionic-native/camera";
import { showNotification, getImageURL } from "../services/HelperMethod";
import { toast } from "react-toastify";
import LoadingIndicator from "../components/LoadingIndicator";
import { Chooser } from "@ionic-native/chooser";

const CreateTerminal = (props: any) => {
  const apiService = ApiService();

  const [pinPad, setPinPad] = useState("");
  const [isValid, setIsValid] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [lanes, setStoreLanes] = useState([]);
  const [closedLanes, setClosedLanes] = useState([]);
  const [laneID, setLaneID] = useState("");
  const [laneName, setLaneName] = useState("");
  const [barcode, setBarcode] = useState("");
  const [serialNumber, setSerialNumber] = useState("");
  const [modelNumber, setModelNumber] = useState("");
  const [date, setDate] = useState(moment());
  const [initialCost, setInitCost] = useState(0);
  const [replacementCost, setReplacementCost] = useState(0);
  const [verificationFrequency, setVerificationFrequency] = useState("2");
  const [description, setDescription] = useState("");
  const [note, setNote] = useState("");
  const [assetType, setAssetType] = useState(0);
  const [imageMain, setImageMain] = useState("");
  const [imageFront, setImageFront] = useState("");
  const [imageBack, setImageBack] = useState("");
  const [imageLeft, setImageLeft] = useState("");
  const [imageRight, setImageRight] = useState("");
  const [imageTop, setImageTop] = useState("");
  const [imageBottom, setImageBottom] = useState("");
  const [isSetMainImage, setIsSetMainImage] = useState(false);
  const [additionalImages, setAdditionalImages] = useState([]);
  const [additionalAttachments, setAdditionalAttachments] = useState([]);

  const options: CameraOptions = {
    destinationType: Camera.DestinationType.DATA_URL,
  };

  useEffect(() => { checkValidations(); });

  useEffect(() => {
	setIsLoading(true);
	apiService
	.getOpenLanes(props?.match?.params?.storeId)
	.then((data: any) => {
		data = data.data;
		setStoreLanes(data);
		handleEdit();
	})
	.catch((err) => {
		setIsLoading(false);
		showNotification(err.message);
	});
  }, []);

  const openScanner = async () => {
	try {
		const data = await BarcodeScanner.scan({ resultDisplayDuration: 0 });
		return data.text;
	} catch (err) {
		showNotification("Invalid Barcode Please Try Again");
	}
};

  function handleEdit() {
    if (props?.match?.params?.assetId) {
		apiService
        .getTerminalById(props?.match?.params?.assetId)
        .then((data: any) => {
          data = data.data;
		  setLaneID(data.laneId || "");
		  setLaneName(data.lane.name || "");
          setSerialNumber(data.serial || "");
		  setImageFront(data.frontImageURL || "");
		  setImageBack(data.backImageURL || "");
		  setImageLeft(data.leftImageURL || "");
		  setImageRight(data.rightImageURL || "");
          setModelNumber(data.model || "");
          setDate(moment(data.datePlaced).local());
          setNote(data.notes || "");
          setBarcode(data.assetTag || "");
          setDescription(data.description || "");

          setIsLoading(false);
        })
        .catch((err) => {
          showNotification(
			console.error(err, "Error while getting terminal data! go back and try again")
          );
        });

		apiService.getVerificationEquipment(props?.match?.params?.assetId)
		.then((data: any) => {
			data = data.data;
			setVerificationFrequency(data.frequency)
		})
    } else {
      setIsLoading(false);
    }

  }

  function handleFormErrors(err) {
    if (err) {
		showNotification("Error! try again");
    }
  }

  function checkValidations() {
    if (serialNumber && modelNumber && barcode) {
      setIsValid(true);
    } else {
      setIsValid(false);
    }
  }

  function createTerminal() {
    setIsLoading(true);
	
	let data = {
		equipmentId: props?.match?.params?.assetId,
		serial: serialNumber,
		assetTag: barcode,
		model: modelNumber,
		laneId: laneID,
		PlacedBy: 0,
		datePlaced: date,
  
		imageFront,
		imageBack,
  
		imageMain: "NotBase64",
		imageTop: "NotBase64",
		imageLeft,
		imageBottom: "NotBase64",
		imageRight,

		notes: note,
	  };

    apiService
      .createEquipment(data)
	  .then(async (data: any) => {
        data = data.data;

        setIsLoading(false);

        if (props?.match?.params?.assetId) {
			showNotification("Terminal Updated!");
        } else {
			showNotification("Terminal Created!");
        }

		await createVerification(laneID);

		props.history.push("/terminals/" + props?.match?.params?.storeId);
      })
      .catch((err) => {
        setIsLoading(false);
        handleFormErrors(err?.response?.data?.errors);
      });
	  
  }

  async function createVerification(laneId) {
    setIsLoading(true);
    //if (verificationUser && verificationFrequency && laneId && verificationDueDate && verificationEndDate) {
    let data = {
      LaneId: laneId,
      dueDate: date,
      // EndDate: verificationEndDate,
      ReceiveNotifications: true,
      frequency: verificationFrequency,
    };

    try {
      await apiService.createVerification(data);
    } catch (err) {
    }
    //}
    setIsLoading(false);
  }

  function submitButton() {
    if (isValid) {
      return (
        <p
          onClick={() => {
            createTerminal();
          }}
          className="center-x success"
        >
          <i className="fas fa-check"></i>
        </p>
      );
    } else {
      return (
        <p onClick={() => {}} className="center-x">
          <i className="fas fa-times"></i>
        </p>
      );
    }
  }

  function getPicture(section, arrItem = 0) {
    Camera.getPicture(options).then((data) => {
      if (section == "front *") {
        setImageFront("data:image/png;base64," + data);
      } else if (section == "back") {
        setImageBack("data:image/png;base64," + data);
      } else if (section == "Upper Left *") {
        setImageLeft("data:image/png;base64," + data);
      } else if (section == "Lower Right *") {
        setImageRight("data:image/png;base64," + data);
      } else if (section == "main") {
        setImageMain("data:image/png;base64," + data);
      }
      if (section == "additionalImage") {
        var additionalImageRef = [...additionalImages];
        additionalImageRef[arrItem] = "data:image/png;base64," + data;
        setAdditionalImages(additionalImageRef);
      }
    });
  }

  useEffect(() => {
  }, [additionalAttachments]);

  function getFile(arrItem = 0) {
    Chooser.getFile("application/pdf")
      .then((file) => {
        var additionalImageRef = [...additionalAttachments];
        additionalImageRef[arrItem] = file.dataURI;
        setAdditionalAttachments(additionalImageRef);
      })
      .catch((err) => {
        console.log(err, "err");
      });
  }

  function getTitle() {
    if (props?.match?.params?.assetId) {
      return "Edit Terminal";
    } else {
      return "Create Terminal";
    }
  }

  function getCurrentLane() {

	if (props?.match?.params?.assetId) {
		return (<input disabled
		className="form-control"
		value={laneName}
		type="text"
		placeholder={laneName}
	  />)
	  } else {
		return (
			<select
				value={laneID}
				onChange={(e) => {
					setLaneID(e.target.value);
				}}
				className="form-control sign-in-inputs"
				id="frequency"
				name="frequency"
			>
				<option>Select Lane</option>

				{lanes.map((lane) => {
					return (
					<React.Fragment key={lane.id}>
						<option value={lane.id}>{lane.name}</option>
					</React.Fragment>
					);
				})}
			</select>
			)
	  }

}

  return (
    <IonPage className="safe-view">
      <IonHeader>
        <TitleBar title={getTitle()} />
      </IonHeader>

      <IonContent>
        <div className="row mb-5">
          <LoadingIndicator isLoading={isLoading} />

          <div className="col-sm-12 bottom-stripe" />
          <div className="col-sm-12 bottom-stripe">
            <div className="row">
              <div className="col-8 d-flex align-items-center">
                <p className="pl-4 m-0 font-weight-bold">Front *</p>
              </div>
              <div className="col-4 text-right">
                <img
                  style={{ width: "80px" }}
                  onClick={() => {
                    getPicture("front *");
                  }}
                  src={getImageURL(imageFront)}
                  alt=""
                />
              </div>
              <div className="col-8 d-flex align-items-center">
                <p className="pl-4 m-0 font-weight-bold">Upper Left *</p>
              </div>
              <div className="col-4 text-right">
                <img
                  style={{ width: "80px" }}
                  onClick={() => {
                    getPicture("Upper Left *");
                  }}
                  src={getImageURL(imageLeft)}
                  alt=""
                />
              </div>
              <div className="col-8 d-flex align-items-center">
                <p className="pl-4 m-0 font-weight-bold">Lower Right *</p>
              </div>
              <div className="col-4 text-right">
                <img
                  style={{ width: "80px" }}
                  onClick={() => {
                    getPicture("Lower Right *");
                  }}
                  src={getImageURL(imageRight)}
                  alt=""
                />
              </div>
              <div className="col-8 d-flex align-items-center">
                <p className="pl-4 m-0 font-weight-bold">Back</p>
              </div>
              <div className="col-4 text-right">
                <img
                  style={{ width: "80px" }}
                  onClick={() => {
                    getPicture("back");
                  }}
                  src={getImageURL(imageBack)}
                  alt=""
                />
              </div>
            </div>
          </div>

          <div className="col-sm-12 col-white">

		  <div className="form-group mt-4 pl-3 pr-3 w-100">
                        <label className="pl-2 font-weight-bold" htmlFor="frequency">
                            Lane *
                        </label>
                       {getCurrentLane()}
            </div>

            <div className="mt-4 pl-3 pr-3 w-100">
              <label className="pl-2 font-weight-bold">PCeyeD Barcode *</label>
              <div style={{display: "flex"}}>
				<input
					className="form-control"
					value={barcode}
					onChange={(e) => {
					setBarcode(e.target.value);
					}}
					type="text"
					placeholder=""
				/>
				<button onClick={() => { openScanner().then(res => {
					setBarcode(res)
				}); }}>	
					<i className="fas fa-solid fa-barcode footer-icon mt-1"></i>
                </button>
			  </div>
			</div>

            <div className="form-group mt-4 pl-3 pr-3 w-100">
              <label className="pl-2 font-weight-bold">Serial Number *</label>
			                <div style={{display: "flex"}}>
							<input
                className="form-control"
                value={serialNumber}
                onChange={(e) => {
                  setSerialNumber(e.target.value);
                }}
                type="text"
                placeholder=""
              />
				<button onClick={() => { openScanner().then(res => {
					setSerialNumber(res)
				}); }}>	
					<i className="fas fa-solid fa-barcode footer-icon mt-1"></i>
                </button>
			  </div>
            </div>

			<div className="form-group mt-4 pl-3 pr-3 w-100">
                        <label className="pl-2 font-weight-bold" htmlFor="model">
                            Model Number *
                        </label>
                        <select
                            value={modelNumber}
                            onChange={(e) => {
                                setModelNumber(e.target.value);
                            }}
                            className="form-control sign-in-inputs"
                            id="frequency"
                            name="frequency"
                        >
							<option> Select Model</option>
							<option> VX520</option>
							<option> 1000SE</option>
							<option> MX915</option>
							<option> MX920</option>
							<option> MX925</option>
							<option> FX150</option>
							<option> FD130</option>
							<option> ISC250</option>
							<option> Lane5000</option>
							<option> PAX S80</option>
							<option> ACR900</option>
							<option> AMP7000</option>
							<option> AMP3000</option>
							<option> AMP5000</option>
							<option> AMP9000</option>
							<option> AMP 6700</option>
							<option> AMP 8200</option>
							<option> AMP 1200</option>
							<option> AMP2000</option>
							<option> AMP8000</option>
							<option> AMP 6500</option>
							<option> AMP 9000</option>
							<option> AMP 7000</option>
							<option> AMP 3000</option>
							<option> KDC500</option>
							<option> AMG9000</option>
							<option> eCARB</option>
                        </select>
                </div>

			<div className="form-group mt-4 pl-3 pr-3 w-100">
                        <label className="pl-2 font-weight-bold" htmlFor="frequency">
                            Frequency *
                        </label>
                        <select
                            value={verificationFrequency}
                            onChange={(e) => {
                                setVerificationFrequency(e.target.value);
                            }}
                            className="form-control sign-in-inputs"
                            id="frequency"
                            name="frequency"
                        >

                            <option value="2">Every two weeks</option>
                            <option value="1">Weekly</option>
                            <option value="3">Monthly</option>
                            <option value="4">Quarterly</option>
                            <option value="5">Yearly</option>
                        </select>
                </div>

            <div className="form-group mt-4 pl-3 pr-3 w-100">
              <label className="pl-2 font-weight-bold">Date *</label>
              <input
                type="date"
                className="form-control"
                value={date.format("YYYY-MM-DD")}
                onChange={(e) => {
                  setDate(moment(e.target.value));
                }}
              />
            </div>

            <div className="form-group mt-4 pl-3 pr-3 w-100">
              <label className="pl-2 font-weight-bold">Notes</label>
              <textarea
                value={note}
                onChange={(e) => {
                  setNote(e.target.value);
                }}
                cols={10}
                rows={5}
                className=" form-control form-textarea"
              ></textarea>
            </div>

            <div className="col-sm-12 bottom-stripe"></div>
          </div>
        </div>
      </IonContent>

      <IonFooter>
        <div className="footer">
          <div className="container">
            <div className="row">
              <div className="col-xs-3 footer-col"></div>
              <div className="col-xs-3 footer-col-center">
                <span className="center-x-link">{submitButton()}</span>
              </div>
              <div className="col-xs-3 footer-col"></div>
            </div>
          </div>
        </div>
      </IonFooter>
    </IonPage>
  );
};

export default CreateTerminal;
