import firebase from "firebase/app";
import "firebase/storage";

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCjLl74GO4gjO6gqhgEuPlGOJfeYj3-5V0",
  authDomain: "pcid-279019.firebaseapp.com",
  projectId: "pcid-279019",
  storageBucket: "pcid-279019.appspot.com",
  messagingSenderId: "394026723720",
  appId: "1:394026723720:web:d676f16f506c2344d2f23d",
  measurementId: "G-98PTTQ2ZWN",
};

firebase.initializeApp(firebaseConfig);

const storage = firebase.storage();

export { storage, firebase as default };
