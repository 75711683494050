/* eslint-disable */

import React, { useState, useEffect } from "react";
import ListItem from "../../../components/ListItem";
import ApiService from "../../../services/ApiService";
import { IonPage, IonHeader, IonContent } from "@ionic/react";
import Footer from "../../../components/Footer";
import LogoBar from "../../../components/LogoBar";
import { showNotification } from "../../../services/HelperMethod";
import LoadingIndicator from "../../../components/LoadingIndicator";

const AssetStores = (props: any) => {
  const apiService = ApiService();

  const [maintenances, setMaintenance] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);

    apiService.getMaintenanceStore().then((data: any) => {
      data = data.data;

      setMaintenance(data);
      setIsLoading(false);
    }).catch((err) => {
      setIsLoading(false);
      showNotification(err.message);
    });
  }, []);

  return (
    <IonPage className="safe-view">
      <IonHeader>
        <LogoBar />
      </IonHeader>

      <IonContent>
        <div>
          <h5 className="text-center mb-5 mt-5">Asset Tracking Management</h5>
          <LoadingIndicator isLoading={isLoading} />
          <div className="row mb-5">
            {maintenances.map((maintenance) => {
              return (
                <ListItem key={maintenance.store?.id} to={"/assets/" + maintenance.store?.id} imageSrc={maintenance.store?.imageURL}>
                  <div className="row">
                    <div className="col-12">
                      <p className="store-title pl-3">
                        <span className="float-left store-title-text">
                          {maintenance.store?.name}
                        </span>
                      </p>
                    </div>
                    <div className="col-12">
                      <p className="store-description pl-3">
                        {maintenance.store?.address}
                      </p>
                    </div>
                  </div>
                </ListItem>
              );
            })}
          </div>
        </div>
      </IonContent>
      
      <Footer />
    </IonPage>
  );
};

export default AssetStores;
