/* eslint-disable */

import React, { useState, useEffect } from "react";
import ListItem from "../../../components/ListItem";
import ApiService from "../../../services/ApiService";
import { IonPage, IonHeader, IonContent } from "@ionic/react";
import Footer from "../../../components/Footer";
import LogoBar from "../../../components/LogoBar";
import { showNotification } from "../../../services/HelperMethod";
import LoadingIndicator from "../../../components/LoadingIndicator";

const VerificationStores = (props: any) => {
  const apiService = ApiService();

  const [verifications, setVerifications] = useState([]);
  const [maintenances, setMaintenance] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);

    apiService
      .getVerificationsStore()
      .then((data: any) => {
        data = data.data;
        setVerifications(data);
      })
      .catch((err) => {
        setIsLoading(false);
        showNotification(err.message);
      });

    apiService
      .getMaintenanceStore()
      .then((data: any) => {
        data = data.data;

        setMaintenance(data);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        showNotification(err.message);
      });
  }, []);

  return (
    <IonPage className="safe-view">
      <IonHeader>
        <LogoBar />
      </IonHeader>

      <IonContent>
        <div>
          <h5 className="text-center mb-5 mt-5">
            Terminal Tracking Management
          </h5>
          <LoadingIndicator isLoading={isLoading} />
          <div className="col-12">
            <div className="row">
              {verifications.map((verification) => {
                return (
                  <ListItem
                    key={verification.store.id}
                    to={"/terminals/" + verification.store.id}
                    imageSrc={verification.store.imageURL}
                  >
                    <div className="row">
                      <div className="col-12">
                        <div className="store-title pl-3">
                          <span className="float-left store-title-text">
                            {verification.store.name}
                          </span>
                        </div>
                      </div>
                      <div className="col-12">
                        <p className="store-description pl-3">
                          {verification.store.address} &nbsp;|&nbsp;
                          {verification.store.city} &nbsp;|&nbsp;
                          {verification.store.state}
                        </p>
                      </div>
                    </div>
                  </ListItem>
                );
              })}
            </div>
          </div>
        </div>
      </IonContent>

      <Footer />
    </IonPage>
  );
};

export default VerificationStores;
